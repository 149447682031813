import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { DataService } from 'src/app/shared/services/data.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';

@Injectable({
  providedIn: 'root',
})
export class MeetingURLService {
  //Get our url
  private _href = new URL(window.location.href);

  constructor(
    private _dataService: DataService,
    private _translate: TranslateService,
    private _clipboard: Clipboard,
    private _meetingService: MeetingsService
  ) {}

  async copyMeetingUrl(MeetingToken?: string, generatedToken?: string) {
    // Show notification, premature copying
    let copiedMsg = this._translate.instant('meeting-room.invitation-copied');

    this._dataService.notification(copiedMsg);

    // If there are not meeting token argument, it copies personal room meeting token
    const token =
      MeetingToken ||
      (await this._meetingService.getPersonalRoom().toPromise()).token;
    if (generatedToken) {
      this._clipboard.copy(
        this._href.protocol +
          '//' +
          this._href.host +
          '/meeting/' +
          token +
          `?meetingToken=${generatedToken}`
      );
    } else {
      this._clipboard.copy(
        this._href.protocol + '//' + this._href.host + '/meeting/' + token
      );
    }
  }
}
