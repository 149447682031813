<div class="example-container">
  <!--side navbar container-->
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav
      #sidenav
      [mode]="panelOpenState ? 'side' : 'over'"
      [fixedInViewport]="true"
      [opened]="false"
    >
      <div *ngIf="dataService.currentUser" class="header-user-info">
        <div class="header-name">{{ dataService.currentUser.username }}</div>
        <div class="header-email">{{ dataService.currentUser.email }}</div>
        <div class="header-avatar-container">
          <img class="header-avatar" src="assets/images/profile.png" />
        </div>
      </div>
      <app-menu></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="example-toolbar" *ngIf="false"
        ><!--*ngIf="dataService.url != '/login'">-->
        <mat-toolbar-row>
          <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
          <img src="assets/images/logo.png" width="20" height="40" />
          <span style="margin-left: 10px" class="title">{{
            "title" | translate
          }}</span>
          <span class="example-spacer"></span>

          <button mat-button [matMenuTriggerFor]="menu" class="menu-card">
            <div class="menu-info">
              <img class="menu-avatar" src="assets/images/profile.png" />
              <span
                *ngIf="dataService.currentUser"
                class="username mr-12"
                fxhide=""
                fxshow.gt-sm=""
                >{{ dataService.currentUser.username }}</span
              >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <button routerLink="/logout" mat-menu-item>
              <mat-icon>input</mat-icon>
              <span>{{ "login.logout" | translate }}</span>
            </button>
          </mat-menu>
          <mat-divider vertical style="height: 64px"></mat-divider>
          <button mat-button [matMenuTriggerFor]="langMenu" class="menu-card">
            <span *ngIf="currentLang == 'ar'">AR</span>
            <span *ngIf="currentLang == 'en'">EN</span>
          </button>
          <mat-menu #langMenu="matMenu">
            <button (click)="useLanguage('en')" mat-menu-item>
              <span *ngIf="currentLang == 'ar'">الإنجليزية</span>
              <span *ngIf="currentLang == 'en'">English</span>
            </button>
            <button (click)="useLanguage('ar')" mat-menu-item>
              <span *ngIf="currentLang == 'ar'">العربية</span>
              <span *ngIf="currentLang == 'en'">Arabic</span>
            </button>
          </mat-menu>
        </mat-toolbar-row>
      </mat-toolbar>
      <!-- <app-navbar></app-navbar> -->
      <app-main
        class="in_center"
        [ngStyle]="{
          'align-items': isSettings ? 'flex-start' : 'center'
        }"
      >
        <ng-content></ng-content>
      </app-main>
      <!-- <app-footer *ngIf="meetingRoom"></app-footer> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
