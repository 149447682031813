<div class="settings-side">
  <div class="back-meeting" (click)="close()">
    <span>{{ "waiting.back-meeting" | translate }}</span>
    <span>{{ kortobaaPkg.meetingTimer.timer }}</span>
  </div>
  <div class="header-info">
    <h4 class="subtitle2">{{ "meeting-room.settings" | translate }}</h4>

    <a aria-label="close meeting side control" (click)="close()">
      <mat-icon color="primary">close</mat-icon>
    </a>
  </div>

  <div *ngIf="showVideo">
    <div class="audio-tap">
      <div class="settings-section mic-settings">
        <mat-label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <mask
              id="mask0_2249_42575"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2249_42575)">
              <path
                d="M12 13C11.4359 13 10.9615 12.8077 10.5769 12.4231C10.1923 12.0385 10 11.5641 10 11V5C10 4.4359 10.1923 3.96154 10.5769 3.57693C10.9615 3.19231 11.4359 3 12 3C12.5641 3 13.0385 3.19231 13.4231 3.57693C13.8077 3.96154 14 4.4359 14 5V11C14 11.5641 13.8077 12.0385 13.4231 12.4231C13.0385 12.8077 12.5641 13 12 13ZM11.5 20V16.9827C10.0564 16.8558 8.81762 16.2827 7.78366 15.2635C6.74967 14.2442 6.1673 12.9981 6.03653 11.525C6.01603 11.3827 6.05257 11.2596 6.14616 11.1558C6.23974 11.0519 6.35769 11 6.50001 11C6.64231 11 6.76122 11.051 6.85673 11.1529C6.95225 11.2548 7.01411 11.3769 7.04231 11.5192C7.17309 12.8013 7.7138 13.8686 8.66443 14.7212C9.61506 15.5737 10.7269 16 12 16C13.2898 16 14.4058 15.5696 15.3481 14.7087C16.2904 13.8478 16.8269 12.7846 16.9577 11.5192C16.9859 11.3769 17.0478 11.2548 17.1433 11.1529C17.2388 11.051 17.3577 11 17.5 11C17.6423 11 17.7603 11.0519 17.8539 11.1558C17.9474 11.2596 17.984 11.3827 17.9635 11.525C17.8327 12.9648 17.2545 14.1994 16.2289 15.2289C15.2032 16.2583 13.9603 16.8429 12.5 16.9827V20C12.5 20.1423 12.4522 20.2612 12.3567 20.3567C12.2612 20.4522 12.1423 20.5 12 20.5C11.8577 20.5 11.7388 20.4522 11.6433 20.3567C11.5478 20.2612 11.5 20.1423 11.5 20ZM12 12C12.2833 12 12.5208 11.9042 12.7125 11.7125C12.9042 11.5208 13 11.2833 13 11V5C13 4.71667 12.9042 4.47917 12.7125 4.2875C12.5208 4.09583 12.2833 4 12 4C11.7167 4 11.4792 4.09583 11.2875 4.2875C11.0958 4.47917 11 4.71667 11 5V11C11 11.2833 11.0958 11.5208 11.2875 11.7125C11.4792 11.9042 11.7167 12 12 12Z"
                fill="#1A1A1A"
              />
            </g>
          </svg>

          <!-- <span class="material-symbols-outlined"> mic </span> -->
          {{ "create-meeting.mic-title" | translate }}
        </mat-label>

        <div class="media-options">
          <!-- <mat-label>{{ "settings.mic" | translate }}</mat-label> -->

          <mat-form-field
            class="bodytext-normal select-option"
            color="primary"
            appearance="fill"
            [ngClass]="{
              'disabled-input': userMediaService?.isInputDisabled
            }"
          >
            <mat-select
              [value]="'0'"
              *ngIf="
                !userMediaService?.audioDevicesList?.length ||
                userMediaService?.isInputDisabled
              "
              [disabled]="userMediaService?.isInputDisabled"
              [disableOptionCentering]="true"
              (click)="
                userMediaService?.isInputDisabled ? checkPermission() : ''
              "
            >
              <mat-option
                *ngIf="!userMediaService?.isInputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.default-mic" | translate }}
              </mat-option>
              <mat-option
                *ngIf="userMediaService?.isInputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.input-disabled" | translate }}
              </mat-option>
            </mat-select>
            <mat-select
              value="default-mic"
              [(ngModel)]="userMediaService.audioDeviceId"
              (selectionChange)="changed('audio')"
              [disableOptionCentering]="true"
              *ngIf="
                userMediaService?.audioDevicesList?.length &&
                !userMediaService?.isInputDisabled
              "
            >
              <ng-container
                *ngFor="let device of userMediaService.audioDevicesList"
              >
                <mat-option class="bodytext-normal" [(value)]="device.deviceId">
                  {{ device.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- NOTE: DONNOT remove (UI without logic) -->
          <!-- <button mat-flat-button class="main-btn">
            {{ "settings.test" | translate }}
          </button> -->
        </div>
      </div>
    </div>

    <div class="audio-tap">
      <div class="settings-section mic-settings">
        <mat-label>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <mask
              id="mask0_4446_87270"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4446_87270)">
              <path
                d="M18.6921 11.9748C18.6921 10.6083 18.322 9.36116 17.5816 8.23333C16.8415 7.1055 15.8488 6.26166 14.6036 5.70183C14.4691 5.63633 14.3691 5.54366 14.3036 5.42383C14.2383 5.304 14.2327 5.18108 14.2869 5.05508C14.3519 4.92075 14.4591 4.83375 14.6086 4.79408C14.7579 4.75425 14.9082 4.76708 15.0594 4.83258C16.4582 5.48508 17.58 6.44925 18.4249 7.72508C19.2697 9.00091 19.6921 10.4172 19.6921 11.9741C19.6921 13.5309 19.2697 14.9475 18.4249 16.2238C17.58 17.5002 16.4582 18.4646 15.0594 19.1171C14.9082 19.1826 14.7579 19.1954 14.6086 19.1556C14.4591 19.1159 14.3519 19.0289 14.2869 18.8946C14.2327 18.7686 14.2383 18.6457 14.3036 18.5258C14.3691 18.406 14.4691 18.3133 14.6036 18.2478C15.8488 17.688 16.8415 16.8442 17.5816 15.7163C18.322 14.5885 18.6921 13.3413 18.6921 11.9748ZM7.73062 13.9998H5.11537C4.88637 13.9998 4.69453 13.9224 4.53987 13.7676C4.38503 13.6127 4.30762 13.4209 4.30762 13.1921V10.8076C4.30762 10.5787 4.38503 10.3869 4.53987 10.2321C4.69453 10.0772 4.88637 9.99983 5.11537 9.99983H7.73062L10.4154 7.31508C10.578 7.15241 10.7675 7.11508 10.9836 7.20308C11.1996 7.29108 11.3076 7.44833 11.3076 7.67483V16.3248C11.3076 16.5513 11.1996 16.7086 10.9836 16.7966C10.7675 16.8846 10.578 16.8472 10.4154 16.6846L7.73062 13.9998ZM15.8076 12.0021C15.8076 12.5496 15.707 13.0678 15.5056 13.5568C15.3043 14.0458 15.0229 14.4697 14.6614 14.8286C14.5447 14.9184 14.4181 14.9335 14.2816 14.8738C14.1451 14.8142 14.0769 14.71 14.0769 14.5613V9.38833C14.0769 9.23966 14.1451 9.1355 14.2816 9.07583C14.4181 9.01616 14.5447 9.03016 14.6614 9.11783C15.0229 9.48283 15.3043 9.91783 15.5056 10.4228C15.707 10.928 15.8076 11.4544 15.8076 12.0021ZM10.3076 8.84983L8.15762 10.9998H5.30762V12.9998H8.15762L10.3076 15.1498V8.84983Z"
                fill="#1A1A1A"
              />
            </g>
          </svg>

          <!-- <span class="material-symbols-outlined"> mic </span> -->
          {{ "settings.speaker" | translate }}
        </mat-label>

        <div class="media-options">
          <!-- <mat-label>{{ "settings.audio" | translate }}</mat-label> -->

          <mat-form-field
            class="bodytext-normal select-option"
            color="primary"
            appearance="fill"
            [ngClass]="{
              'disabled-input': userMediaService?.isOuputDisabled
            }"
          >
            <mat-select
              [value]="'0'"
              *ngIf="
                !userMediaService?.outputDevicesList?.length ||
                userMediaService?.isOuputDisabled
              "
              [disabled]="userMediaService?.isOuputDisabled"
              (click)="
                userMediaService?.isOuputDisabled ? checkPermission() : ''
              "
            >
              <mat-option
                *ngIf="!userMediaService?.isOuputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.default-speaker" | translate }}
              </mat-option>
              <mat-option
                *ngIf="userMediaService?.isOuputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.speaker-disabled" | translate }}
              </mat-option>
            </mat-select>
            <mat-select
              value="default-mic"
              [(ngModel)]="userMediaService.outputDeviceId"
              (selectionChange)="changed('output')"
              [disableOptionCentering]="true"
              *ngIf="
                userMediaService?.outputDevicesList?.length &&
                !userMediaService?.isOuputDisabled
              "
            >
              <ng-container
                *ngFor="let device of userMediaService.outputDevicesList"
              >
                <mat-option class="bodytext-normal" [(value)]="device.deviceId">
                  {{ device.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <!-- NOTE: DONNOT remove (UI without logic) -->
          <!-- <button mat-flat-button class="main-btn">
            {{ "settings.test" | translate }}
          </button> -->
        </div>
      </div>
    </div>

    <div class="video-tap">
      <mat-label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id="mask0_2249_42601"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2249_42601)">
            <path
              d="M5.09614 19C4.63588 19 4.25158 18.8458 3.94324 18.5375C3.63491 18.2292 3.48074 17.8449 3.48074 17.3846V6.61537C3.48074 6.15512 3.63491 5.77083 3.94324 5.4625C4.25158 5.15417 4.63588 5 5.09614 5H15.8654C16.3256 5 16.7099 5.15417 17.0183 5.4625C17.3266 5.77083 17.4808 6.15512 17.4808 6.61537V11.2692L19.8231 8.92693C19.9513 8.79871 20.0994 8.76665 20.2673 8.83075C20.4353 8.89487 20.5192 9.01795 20.5192 9.2V14.8C20.5192 14.982 20.4353 15.1051 20.2673 15.1692C20.0994 15.2333 19.9513 15.2013 19.8231 15.0731L17.4808 12.7308V17.3846C17.4808 17.8449 17.3266 18.2292 17.0183 18.5375C16.7099 18.8458 16.3256 19 15.8654 19H5.09614ZM5.09614 18H15.8654C16.0449 18 16.1923 17.9423 16.3077 17.8269C16.4231 17.7115 16.4808 17.5641 16.4808 17.3846V6.61537C16.4808 6.43589 16.4231 6.28846 16.3077 6.17307C16.1923 6.05769 16.0449 6 15.8654 6H5.09614C4.91666 6 4.76923 6.05769 4.65384 6.17307C4.53846 6.28846 4.48077 6.43589 4.48077 6.61537V17.3846C4.48077 17.5641 4.53846 17.7115 4.65384 17.8269C4.76923 17.9423 4.91666 18 5.09614 18Z"
              fill="#1A1A1A"
            />
          </g>
        </svg>
        {{ "create-meeting.video-title" | translate }}
      </mat-label>

      <mat-form-field
        class="bodytext-normal select-option"
        color="primary"
        appearance="fill"
        [ngClass]="{
          'disabled-input': userMediaService?.isVideoDisabled
        }"
      >
        <mat-select
          [value]="'0'"
          *ngIf="
            !userMediaService?.videoDevicesList?.length ||
            userMediaService?.isVideoDisabled
          "
          [disableOptionCentering]="true"
          [disabled]="userMediaService?.isVideoDisabled"
          (click)="userMediaService?.isVideoDisabled ? checkPermission() : ''"
        >
          <mat-option
            *ngIf="!userMediaService?.isVideoDisabled"
            class="bodytext-normal"
            value="0"
            selected="true"
          >
            {{ "settings.default-cam" | translate }}
          </mat-option>
          <mat-option
            *ngIf="userMediaService?.isVideoDisabled"
            class="bodytext-normal"
            value="0"
            selected="true"
          >
            {{ "settings.video-disabled" | translate }}
          </mat-option>
        </mat-select>
        <mat-select
          value="default-cam"
          [disableOptionCentering]="true"
          [(ngModel)]="userMediaService.videoDeviceId"
          (selectionChange)="changed('video')"
          *ngIf="
            userMediaService?.videoDevicesList?.length &&
            !userMediaService?.isVideoDisabled
          "
        >
          <ng-container *ngFor="let cam of userMediaService.videoDevicesList">
            <mat-option
              *ngIf="cam.kind === 'videoinput'"
              [value]="cam.deviceId"
            >
              {{ cam.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="video-preview">
        <mat-icon
          *ngIf="userMediaService.isVideoDisabled"
          aria-hidden="false"
          aria-label="video off"
          >videocam_off</mat-icon
        >
        <video
          *ngIf="!userMediaService.isVideoDisabled"
          [srcObject]="userMediaService.videoStream"
          [muted]="true"
          autoplay
          loop
          muted
          playsinline
        ></video>

        <div class="preview-text" *ngIf="!userMediaService.isVideoDisabled">
          <span>{{ "settings.private-preview" | translate }} </span>
          <span class="preview-note">{{
            "settings.no-one-but-you-can-see-it" | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
