import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SocketService } from '../services/socket.service';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/shared/services/lang.service';

@Component({
  selector: 'app-privacy-reject',
  templateUrl: './privacy-reject.component.html',
  styleUrls: ['./privacy-reject.component.scss'],
})
export class PrivacyRejectComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PrivacyRejectComponent>,
    private socketService: SocketService,
    public lang: LangService
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  confirm() {
    this.socketService.disconnect();
    if (this.iOS() || navigator.userAgent.includes('Firefox')) {
      window.location.href = environment.REDIRECT_URL;
    } else {
      window.close();
    }
  }
}
