import { Component } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-main',
  template: `
    <div class="content-container">
      <div
        [ngClass]="{ 'content-area': dataService.url != '/login' }"
        class="content"
      >
        <img
          class="bg-image"
          src="../../../assets/images/rate/bg-dialog.svg"
          alt=""
        />
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        flex: 1;
      }
      .content-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .content {
        width: 100%;
      }
      .bg-image {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `,
  ],
})
export class MainComponent {
  constructor(public dataService: DataService) {}
}
