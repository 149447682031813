<div class="not-found">
  <img src="assets/images/page-not-found.png" alt="Page not found" />
  <p class="subtitle1" style="color: #000">
    {{ "errors.sorry-page-not-found" | translate }}
  </p>
  <button
    class="main-btn button18"
    mat-flat-button
    color="primary"
    (click)="close()"
  >
    اغلاق الصفحة
  </button>
</div>
