<div
  class="settings-header"
  mat-dialog-title
  [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
>
  <a (click)="onNoClick()" class="close-btn">
    <mat-icon>close</mat-icon>
  </a>
  <h4 class="subtitle1">
    {{ "meeting-room.settings" | translate }}
  </h4>
</div>

<mat-dialog-content class="custom-scroll setting-content">
  <mat-tab-group
    [(selectedIndex)]="tabIndex"
    (selectedTabChange)="onTabChange()"
    [ngClass]="tabIndex == 1 && 'video-group'"
  >
    <!-- Start audio settings -->
    <mat-tab>
      <ng-template mat-tab-label>
        <figure>
          <img
            [src]="
              tabIndex == 0
                ? '../../../assets/images/icons/speaker_phone_active.svg'
                : '../../../assets/images/icons/speaker_phone.svg'
            "
            [alt]="'settings.audio-settings' | translate"
          />
        </figure>
        {{ "settings.audio-settings" | translate }}
      </ng-template>
      <div class="audio-tap">
        <div
          class="settings-section mic-settings"
          [ngStyle]="{
            'padding-bottom': userMediaService?.isInputDisabled
              ? '6rem'
              : '5rem'
          }"
        >
          <label>{{ "settings.mic" | translate }}</label>

          <div class="media-options">
            <!-- <mat-label>{{ "settings.mic" | translate }}</mat-label> -->

            <mat-form-field
              class="bodytext-normal select-option"
              color="primary"
              appearance="fill"
              [ngClass]="{
                'disabled-input': userMediaService?.isInputDisabled
              }"
            >
              <mat-select
                [value]="'0'"
                *ngIf="
                  !userMediaService?.audioDevicesList?.length ||
                  userMediaService?.isInputDisabled
                "
                [disabled]="userMediaService?.isInputDisabled"
                (click)="
                  userMediaService?.isInputDisabled ? checkPermission() : ''
                "
              >
                <mat-option
                  *ngIf="!userMediaService?.isInputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.default-input" | translate }}
                </mat-option>
                <mat-option
                  *ngIf="userMediaService?.isInputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.input-disabled" | translate }}
                </mat-option>
              </mat-select>
              <mat-select
                value="default-mic"
                [(ngModel)]="userMediaService.audioDeviceId"
                (selectionChange)="changed('audio')"
                *ngIf="
                  userMediaService?.audioDevicesList?.length &&
                  !userMediaService?.isInputDisabled
                "
              >
                <ng-container
                  *ngFor="let device of userMediaService.audioDevicesList"
                >
                  <mat-option
                    class="bodytext-normal"
                    [(value)]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <!-- NOTE: DONNOT remove (UI without logic) -->
            <!-- <button mat-flat-button class="main-btn">
              {{ "settings.test" | translate }}
            </button> -->
          </div>

          <div
            class="audio-level-container"
            *ngIf="!userMediaService?.isInputDisabled"
          >
            <label class="bodytext1">{{
              "settings.input-level" | translate
            }}</label>
            <div class="audio-level">
              <ng-container *ngFor="let i of inputLevels">
                <span
                  class="sound-unit"
                  [ngClass]="{ on: inputLevel > i }"
                ></span>
              </ng-container>
            </div>
          </div>

          <!-- NOTE: DONNOT remove (UI without logic) -->
          <!-- <div class="audio-volume-container">
            <label class="bodytext1">{{
              "settings.input-volume" | translate
            }}</label>
            <mat-slider aria-label="input level"></mat-slider>
          </div> -->
        </div>

        <div class="settings-section">
          <label>{{ "settings.speaker" | translate }}</label>

          <div class="media-options">
            <!-- <mat-label>{{ "settings.audio" | translate }}</mat-label> -->

            <mat-form-field
              class="bodytext-normal select-option"
              color="primary"
              appearance="fill"
              [ngClass]="{
                'disabled-input': userMediaService?.isOuputDisabled
              }"
            >
              <mat-select
                [value]="'0'"
                *ngIf="
                  !userMediaService?.outputDevicesList?.length ||
                  userMediaService?.isOuputDisabled
                "
                [disabled]="userMediaService?.isOuputDisabled"
                (click)="
                  userMediaService?.isOuputDisabled ? checkPermission() : ''
                "
              >
                <mat-option
                  *ngIf="!userMediaService?.isOuputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.default-speaker" | translate }}
                </mat-option>
                <mat-option
                  *ngIf="userMediaService?.isOuputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.speaker-disabled" | translate }}
                </mat-option>
              </mat-select>
              <mat-select
                value="default-mic"
                [(ngModel)]="userMediaService.outputDeviceId"
                (selectionChange)="changed('output')"
                *ngIf="
                  userMediaService?.outputDevicesList?.length &&
                  !userMediaService?.isOuputDisabled
                "
              >
                <ng-container
                  *ngFor="let device of userMediaService.outputDevicesList"
                >
                  <mat-option
                    class="bodytext-normal"
                    [(value)]="device.deviceId"
                  >
                    {{ device.label }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <!-- NOTE: DONNOT remove (UI without logic) -->
            <!-- <button mat-flat-button class="main-btn">
              {{ "settings.test" | translate }}
            </button> -->
          </div>

          <!-- TODO: replace the input level and volume with output  -->
          <!-- <div class="audio-level-container">
            <label class="bodytext1">{{
              "settings.output-level" | translate
            }}</label>
            <div class="audio-level">
              <ng-container *ngFor="let i of inputLevels">
                <span
                  class="sound-unit"
                  [ngClass]="{ on: inputLevel > i }"
                ></span>
              </ng-container>
            </div>
          </div> -->

          <!-- NOTE: DONNOT remove (UI without logic) -->
          <!-- <div class="audio-volume-container">
            <label class="bodytext1">{{
              "settings.output-volume" | translate
            }}</label>
            <mat-slider aria-label="output level"></mat-slider>
          </div> -->
        </div>

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <div class="settings-section">
          <label>{{ "settings.smart-voice" | translate }}</label>

          <mat-checkbox color="accent" name="muteParticipants">
            {{ "settings.adjust-volume-automatically" | translate }}
          </mat-checkbox>

          <mat-checkbox color="accent" name="muteParticipants">
            {{ "settings.unmute-by-pressing-spacebar" | translate }}
          </mat-checkbox>

          <div class="audio-levels settings-toggle-btns">
            <mat-checkbox color="accent" name="muteParticipants" class="">
              <span> {{ "settings.noise-cancellation" | translate }}</span>
              <span class="noise-cancellation-subline">
                {{ "settings.removes-all-background-noise" | translate }}
              </span>
            </mat-checkbox>

            <mat-button-toggle-group
              name="Meeting grid"
              aria-label="Meeting grid"
            >
              <mat-button-toggle checked value="level-1">
                {{ "settings.level-1" | translate }}</mat-button-toggle
              >
              <mat-button-toggle value="level-2">{{
                "settings.level-2" | translate
              }}</mat-button-toggle>

              <mat-button-toggle value="level-3">{{
                "settings.level-3" | translate
              }}</mat-button-toggle>

              <mat-button-toggle value="level-4">{{
                "settings.level-4" | translate
              }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->
        <!-- </div> -->
      </div>
    </mat-tab>
    <!-- End audio settings -->

    <!-- Start video settings -->
    <mat-tab>
      <ng-template mat-tab-label>
        <figure>
          <img
            [src]="
              tabIndex == 1
                ? '../../../assets/images/icons/videocam_active.svg'
                : '../../../assets/images/icons/videocam.svg'
            "
            [alt]="'settings.video-settings' | translate"
          />
        </figure>
        {{ "settings.video-settings" | translate }}
      </ng-template>
      <div class="video-tap">
        <!-- <mat-label>{{ "settings.camera" | translate }}</mat-label> -->

        <mat-form-field
          class="bodytext-normal select-option"
          color="primary"
          appearance="fill"
          [ngClass]="{
            'disabled-input': userMediaService?.isVideoDisabled
          }"
        >
          <mat-select
            [value]="'0'"
            *ngIf="
              !userMediaService?.videoDevicesList?.length ||
              userMediaService?.isVideoDisabled
            "
            [disabled]="userMediaService?.isVideoDisabled"
            (click)="userMediaService?.isVideoDisabled ? checkPermission() : ''"
          >
            <mat-option
              *ngIf="!userMediaService?.isVideoDisabled"
              class="bodytext-normal"
              value="0"
              selected="true"
            >
              {{ "settings.default-video" | translate }}
            </mat-option>
            <mat-option
              *ngIf="userMediaService?.isVideoDisabled"
              class="bodytext-normal"
              value="0"
              selected="true"
            >
              {{ "settings.video-disabled" | translate }}
            </mat-option>
          </mat-select>
          <mat-select
            value="default-cam"
            [(ngModel)]="userMediaService.videoDeviceId"
            (selectionChange)="changed('video')"
            *ngIf="
              userMediaService?.videoDevicesList?.length &&
              !userMediaService?.isVideoDisabled
            "
          >
            <ng-container *ngFor="let cam of userMediaService.videoDevicesList">
              <mat-option
                *ngIf="cam.kind === 'videoinput'"
                [value]="cam.deviceId"
              >
                {{ cam.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div class="video-preview">
          <mat-icon
            *ngIf="userMediaService.isVideoDisabled"
            aria-hidden="false"
            aria-label="video off"
            >videocam_off</mat-icon
          >
          <video
            *ngIf="!userMediaService.isVideoDisabled"
            [srcObject]="userMediaService.videoStream"
            autoplay
            [muted]="true"
          ></video>

          <div class="preview-text" *ngIf="!userMediaService.isVideoDisabled">
            <span>{{ "settings.private-preview" | translate }} </span>
            <span class="preview-note">{{
              "settings.no-one-but-you-can-see-it" | translate
            }}</span>
          </div>
        </div>

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <div class="media-options camera-resolution">
          <label>
            <mat-icon>high_quality</mat-icon>
            {{ "settings.camera-resolution" | translate }}</label
          >

          <mat-form-field
            class="bodytext-normal select-option"
            color="primary"
            appearance="fill"
          >
            <mat-label>{{
              "settings.camera-resolution" | translate
            }}</mat-label>
            <mat-select value="360p">
              <ng-container *ngFor="let cam of ['360p', '720p', '1080p']">
                <mat-option [value]="cam">
                  {{ cam }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
    </mat-tab>
    <!-- End video settings -->

    <!-- Start view settings -->
    <mat-tab>
      <ng-template mat-tab-label>
        <figure>
          <img
            [src]="
              tabIndex == 2
                ? '../../../assets/images/icons/settings_active.svg'
                : '../../../assets/images/icons/settings.svg'
            "
            [alt]="'account.general' | translate"
          />
        </figure>
        {{ "account.general" | translate }}
      </ng-template>
      <div class="view-tap">
        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <mat-checkbox color="accent" name="showMeetingName">
          {{ "settings.show-meeting-name" | translate }}
        </mat-checkbox>

        <mat-checkbox color="accent" name="showTimerForMeeting">
          {{ "settings.show-timer-for-meeting" | translate }}
        </mat-checkbox> -->

        <div class="form-input">
          <mat-label>{{ "settings.theme" | translate }}</mat-label>
          <div class="theme_container">
            <div
              class="theme disabled"
              [ngClass]="{ active: themeSelected == 'default' }"
            >
              <figure>
                <img
                  src="../../../../assets/images/default_system.svg"
                  [alt]="'settings.default-system' | translate"
                />
              </figure>
              <h4>
                {{ "settings.default-system" | translate }}
              </h4>
            </div>

            <div
              class="theme"
              (click)="switchTheme('dark')"
              [ngClass]="{ active: themeSelected == 'dark' }"
            >
              <figure>
                <img
                  src="../../../../assets/images/dark.svg"
                  [alt]="'settings.dark-theme' | translate"
                />
              </figure>
              <h4>
                {{ "settings.dark-theme" | translate }}
              </h4>
            </div>

            <div
              class="theme disabled"
              [ngClass]="{ active: themeSelected == 'light' }"
            >
              <figure>
                <img
                  src="../../../../assets/images/light.svg"
                  [alt]="'settings.light-theme' | translate"
                />
              </figure>
              <h4>
                {{ "settings.light-theme" | translate }}
              </h4>
            </div>
          </div>
        </div>

        <!-- <div class="form-input">
          <mat-label>{{ "account.language" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
          >
            <mat-select [value]="this._lang.currentLang == 'en' ? 'en' : 'ar'">
              <mat-option
                value="ar"
                (click)="this._lang.useLanguage('ar'); this.dialogRef.close()"
              >
                اللغة العربية
              </mat-option>
              <mat-option
                value="en"
                (click)="this._lang.useLanguage('en'); this.dialogRef.close()"
              >
                English
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <!-- <mat-slide-toggle
          color="primary"
          class="custom-slide-toggle"
          [(ngModel)]="isDarkTheme"
          (change)="switchTheme()"
        >
          <label class="select-label">{{
            "settings.activate-night-mode" | translate
          }}</label>
        </mat-slide-toggle> -->

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <label>{{ "settings.participants-view-mode" | translate }}</label> -->

        <!-- <div class="settings-toggle-btns">
          <label class="bodytext1">{{
            "settings.show-as-gallery" | translate
          }}</label>

          <mat-button-toggle-group
            name="Meeting grid"
            aria-label="Meeting grid"
          >
            <mat-button-toggle checked value="9">3*3</mat-button-toggle>
            <mat-button-toggle value="16">4*4</mat-button-toggle>
            <mat-button-toggle value="35">5*7</mat-button-toggle>
            <mat-button-toggle value="45">5*9</mat-button-toggle>
          </mat-button-toggle-group>
        </div> -->
      </div>
    </mat-tab>

    <!-- Start view meeting room -->
    <mat-tab *ngIf="kortobaaPkg.isHost">
      <ng-template mat-tab-label>
        <figure>
          <img
            [src]="
              tabIndex == 3
                ? '../../../assets/images/icons/meeting_room_active.svg'
                : '../../../assets/images/icons/meeting_room.svg'
            "
            [alt]="'settings.meeting-settings' | translate"
          />
        </figure>
        {{ "settings.meeting-settings" | translate }}
      </ng-template>
      <div class="view-tap">
        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <mat-checkbox color="accent" name="showMeetingName">
            {{ "settings.show-meeting-name" | translate }}
          </mat-checkbox>

          <mat-checkbox color="accent" name="showTimerForMeeting">
            {{ "settings.show-timer-for-meeting" | translate }}
          </mat-checkbox> -->

        <mat-slide-toggle
          color="primary"
          class="custom-slide-toggle"
          (click)="saveSettings()"
          [(ngModel)]="meetingSettings.locked"
        >
          <label class="select-label">{{
            "settings.close-meeting" | translate
          }}</label>
        </mat-slide-toggle>

        <mat-slide-toggle
          color="primary"
          class="custom-slide-toggle"
          (click)="saveSettings()"
          [(ngModel)]="meetingSettings.isWaiting"
        >
          <label class="select-label">{{
            "settings.enable-waiting-room" | translate
          }}</label>
        </mat-slide-toggle>

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- <label>{{ "settings.participants-view-mode" | translate }}</label> -->

        <!-- <div class="settings-toggle-btns">
            <label class="bodytext1">{{
              "settings.show-as-gallery" | translate
            }}</label>

            <mat-button-toggle-group
              name="Meeting grid"
              aria-label="Meeting grid"
            >
              <mat-button-toggle checked value="9">3*3</mat-button-toggle>
              <mat-button-toggle value="16">4*4</mat-button-toggle>
              <mat-button-toggle value="35">5*7</mat-button-toggle>
              <mat-button-toggle value="45">5*9</mat-button-toggle>
            </mat-button-toggle-group>
          </div> -->
      </div>
    </mat-tab>
    <!-- End view meeting room -->
  </mat-tab-group>
</mat-dialog-content>

<!-- <mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    {{ "account.cancel" | translate }}
  </button>

  <button
    mat-flat-button
    color="primary"
    class="main-btn"
    [loading]="btnLoading"
    (click)="saveSettings()"
    [mat-dialog-close]="true"
  >
    {{ "account.save" | translate }}
  </button>
</mat-dialog-actions> -->
