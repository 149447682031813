import { Component, OnInit } from '@angular/core';
import { RateService } from './rate.service';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LangService } from 'src/app/shared/services/lang.service';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from '@ngx-translate/core';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';

@Component({
  selector: 'app-rate-call',
  templateUrl: './rate-call.component.html',
  styleUrls: ['./rate-call.component.scss'],
})
export class RateCallComponent implements OnInit {
  constructor(
    private rateService: RateService,
    private _snackBar: MatSnackBar,
    private _lang: LangService,
    private transPipe: TranslatePipe,
    private kortobaaPkg: KortobaaMeetService
  ) {}

  selectedRate = 0;
  textFlag = false;
  starsFlag = false;
  note = '';
  disableBtn = false;

  ngOnInit(): void {}

  changeStars(i) {
    this.selectedRate = i + 1;
    this.starsFlag = true;
  }

  text(event) {
    if (event.target.value.length > 0) {
      this.textFlag = true;
    }
    this.note = event.target.value;
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  submitRate() {
    this.disableBtn = true;
    const data = {
      rate: this.selectedRate,
      note: this.note,
    };

    this.rateService
      .rateCall(this.rateService.callId, data)
      .subscribe((res) => {
        this._snackBar.open(this.transPipe.transform('rate.msg'), '', {
          duration: 2000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          direction: this._lang.direction,
          panelClass: ['in-meeting-snackbar'],
        });
        this.kortobaaPkg.endMeeting();
        this.kortobaaPkg.resetMeetingConnections();
        setTimeout(() => {
          if (this.iOS() || navigator.userAgent.includes('Firefox')) {
            window.location.href = environment.REDIRECT_URL;
          } else {
            window.close();
          }
        }, 1000);
      });
  }
}
