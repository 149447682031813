import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { SocketService } from '../services/socket.service';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { LangService } from 'src/app/shared/services/lang.service';

@Component({
  selector: 'app-leave-meeting-dialog',
  templateUrl: './leave-meeting-dialog.component.html',
  styleUrls: ['./leave-meeting-dialog.component.scss'],
})
export class LeaveMeetingDialogComponent implements OnInit {
  closeMeetingAudio = new Audio('../../../assets/audio/closed meeting.mp3');

  constructor(
    private _router: Router,
    public kortobaaPkg: KortobaaMeetService,
    public dialogRef: MatDialogRef<LeaveMeetingDialogComponent>,
    private socket: SocketService,
    public lang: LangService
  ) {}

  ngOnInit(): void {}

  backToHome() {
    // Lets destroy all connections from component before leaving
    // this.kortobaaPkg.resetMeetingConnections();
    const token = localStorage.getItem('Token');
    if (token) {
      this.kortobaaPkg.endMeeting();
      this.kortobaaPkg.resetMeetingConnections();
      window.close();
    } else {
      this._router.navigate(['/rate']);
    }
  }

  leaveMeeting() {
    this.backToHome();
    this.kortobaaPkg.reconnecting = false;
    this.dialogRef.close();
    this.closeMeetingAudio.play();
  }

  endMeeting() {
    // end meeting function for meeting host
    this.kortobaaPkg.endMeeting();
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
