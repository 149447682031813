import { Inject, Injectable, Injector } from '@angular/core';
import {
  RouterEvent,
  NavigationStart,
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { ToastrService } from 'ngx-toastr';
import { UserService } from '@kortobaa-front/authentication';

import { LangService } from 'src/app/shared/services/lang.service';
import { MeetingInfo } from 'src/app/shared/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {
  href = new URL(window.location.href);
  apiHost: string = '';
  host: string = '';
  url = '/login';
  loading = true;
  currentUser = null;
  formsCols = 2;
  isMeeting = false;
  recordingTimer: any = '00:00:00';
  sharingScreenWithVideo: boolean = false;
  meetingScreensGrid: number;
  homeStatus = {
    type: null, //'kicked'  - 'ended' - 'connectionError'
  };
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  recordingLoading: boolean = false;

  dialogOpen = false;

  constructor(
    private _kortobaaPkgUserService: UserService,
    private _router: Router,
    private _lang: LangService,
    @Inject(Injector) private readonly injector: Injector,
    breakpointObserver: BreakpointObserver
  ) {
    const config = (window as any).appConfig;
    this.apiHost = environment.production
      ? config.host + '/api/'
      : environment.url + 'api/';
    this.host = environment.production ? config.host + '/' : environment.url;
    this._router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 1;
        this.meetingScreensGrid = 8;
        this.isMobile = true;
        this.isTablet = false;
        this.isDesktop = false;
      }
    });

    breakpointObserver.observe([Breakpoints.Tablet]).subscribe((result) => {
      if (result.matches) {
        this.meetingScreensGrid = 8;
        this.isMobile = false;
        this.isTablet = true;
        this.isDesktop = false;
      }
    });

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.formsCols = 2;
        this.meetingScreensGrid = 9;
        this.isMobile = false;
        this.isTablet = false;
        this.isDesktop = true;
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
      this.url = event.url;
      this.checkMeeting(event.url);
      this.currentUser = JSON.parse(localStorage.getItem('User'));
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  checkMeeting(url) {
    if (url.search('/meeting') != -1) {
      this.isMeeting = true;
    } else {
      this.isMeeting = false;
    }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  notification(msg: string, error?: boolean) {
    // if (error) return this.toastrService.error(msg, null, { timeOut: 3000 });
    // return this.toastrService.success(msg, null, { timeOut: 1500 });
  }

  clearNotification(toastId?: number) {
    this.toastrService.clear(toastId);
  }

  giveUserNewTime() {
    const expirationDuration = 1000 * 60 * 60 * 24; // 24 hours
    const prevAccepted: any = localStorage.getItem('accepted');
    const currentTime = new Date().getTime();
    const notAccepted = prevAccepted == undefined;
    const prevAcceptedExpired =
      prevAccepted != undefined &&
      currentTime - prevAccepted > expirationDuration;
    if (notAccepted || prevAcceptedExpired) {
      this.notification('User Regenrated new time and get new id');
      localStorage.setItem('accepted', currentTime.toString());
    }
  }

  //recording meeting timer
  startTime: Date;
  stopTime: Date;
  active: boolean = false;
  paused: boolean = false;
  timeTracked: number;

  /* display() {
    this.recordingTimer = this.getTimeTracked(this.startTime, this.stopTime);
  } */

  timer() {
    if (this.active && !this.paused) {
      this.recordingTimer = this.getTimeTracked(this.startTime, this.stopTime);
      this.stopTime = new Date();
      setInterval(
        () => {
          this.timer();
        },
        0,
        1000
      );
    }
  }

  start() {
    this.startTime = new Date();
    this.stopTime = this.stopTime;
    this.timeTracked = 0;
    this.active = true;
    this.recordingTimer = this.timer();
  }

  pause() {
    this.paused = true;
    this.stopTime = new Date();
    this.timeTracked = this.getTimeTracked(this.startTime, this.stopTime);
  }

  resume() {
    this.startTime = new Date(+new Date() - this.timeTracked);
    this.paused = false;
    this.timer();
  }

  stop() {
    this.active = false;
    if (!this.paused) this.stopTime = new Date();
    this.paused = false;
    this.timeTracked = this.getTimeTracked(this.startTime, this.stopTime);
  }

  getTimeTracked(start, stop) {
    return start && stop ? +stop - +start : 0;
  }

  // TODO: Wait @kortobaa-front/authentication authors to change the return type from 'String' -> 'strign'
  getUserToken(): any {
    return this._kortobaaPkgUserService.getToken();
  }

  getUserData() {
    return this._kortobaaPkgUserService.currentUser;
  }

  setUserSettings(userSettings) {
    localStorage.setItem('settings', JSON.stringify(userSettings));
  }

  getUserSettings() {
    return JSON.parse(localStorage.getItem('settings'));
  }

  setMeetingInfo(meetingInfo: MeetingInfo) {
    sessionStorage.setItem('meetingInfo', JSON.stringify(meetingInfo));
  }

  getMeetingInfo() {
    let meetingInfo: MeetingInfo;
    let meetingInfoJson = sessionStorage.getItem('meetingInfo');
    if (meetingInfoJson) meetingInfo = JSON.parse(meetingInfoJson);
    return meetingInfo;
  }

  clearMeetingInfo() {
    sessionStorage.removeItem('meetingInfo');
  }

  redirectTo(uri: string) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/' + uri]);
  }

  forceReloadSamePage() {
    this.redirectTo(this._router.url);
  }
}
