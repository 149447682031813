import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
const encryptIO = require('../../../assets/js/socketio-encrypt.js');

@Injectable({ providedIn: 'root' })
export class SocketService {
  private socket: any;
  id: any;

  constructor(private router: Router, private route: ActivatedRoute) {
    const arr = router.url.split('/');
    this.id = arr[arr.length - 1].split('?')[0];

    const userToken = this.route.snapshot.queryParams['userToken'];

    if (!userToken) {
      this.socket = io(environment.callUrl, {
        transports: ['websocket'],
        timeout: 40000,
      });
      encryptIO(
        environment.AES_KEY,
        environment.IV_LENGTH,
        environment.ENC_TYPE,
        environment.ENC_MODE
      )(this.socket);
    }
  }

  // Method to emit events to the server
  emitEvent(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  // Method to listen for events from the server
  onEvent(eventName: string, callback: Function) {
    this.socket.on(eventName, callback);
  }

  // listen(eventName: string) {
  //   return new Observable((sub) => {
  //     this.socket.on(eventName, (data) => {
  //       sub.next(data);
  //     });
  //   });
  // }

  // Method to disconnect from the server
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
