// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'https://enterprise.kmeet.io/',
  url: 'https://apidev.halaengage.com/',
  REDIRECT_URL: 'https://dev.halaengage.com',
  // url: 'https://api.halameet.com/',
  TurnIP: 'turn.halameet.com',
  callUrl: 'https://apidev.halaengage.com/call/',
  ENCRYPTION_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvkBdXXINUnRpWTUL365b9Aytuw/jQCa5MmRMF1X8lDIBM2utqca/KXNcR7POBM2NVVmAY2+IdhIgGVqVAKJKmwpXcKPMEMAztKqzc7PFnnMnHHrqAkigxtJ0UTowkfxfn7QQs6dK/hvi7EJXF02rOoqDwRTyL9DjEf4Math13IpsS/pWgXhY/8irD03aLUQbA7+SMnjv1uv/ux8IaZ1uIkMFLUIYoPk1XI7FapnCWfPx3x9jRI7gBIp7f3Ay2O+1HDYSFQcYfic+Nj1nHwPfaZjmpXzxOX6rXRuNakpNv2dwP3HBiB3nfVi3/5v9n2h8U9IL2x5kw52akjCV/Q9j4QIDAQAB',
  API_CLIENT: 'PCNDFkdDTnRpA6Et4X3mS8HskUJQXvdp',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=enterprise.kmeet.io',
  APP_STORE_URL: 'https://apps.apple.com/us/app/kmeet-enterprise/id1584773829',
  timeZone: 'Africa/Cairo',
  timeLocalString: 'en-EG',
  SERVER_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq2AMIcZyOPUJE0qElN1xe1GWFuIzFMDCMsAvuvpQJx2UZDaV3FPF1HMcLSjR9rPHB7F/t4fT9UdSOL+D57c0RVJ0u58arUOxGNnoHDlGp46geJYyYKrNVaDh9U3dZ6IU3a4+qI7H0rqstu6/eyRZnaZDcivOJE5eqi5qhfK6G9gFR25dPw2lp/H41pnWiqEjo68clSoSosZiZPNFa8qowNrhoZJF5/SjVDUMcrwvzkCIwiLhr18N+SEZwn6fke/+rqUkW5FDFED/3Zl12FuJ7Rh8oU8X8s46qHBDr79ogSRaDwucVsOWea9aHUw6s4X9zIypVq2aHlXeafv4XJP48wIDAQAB',
  API_KEY: '3h@jNmC$684nnBujZ!fgHyEwb8Q2@V',
  AES_KEY: '5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)',
  IV_LENGTH: 16,
  ENC_MODE: 'aes-256-cbc',
  ENC_TYPE: 'hex',
  TCP_iceServers: [
    {
      username: 'DlzBo7ulWTNu484SL0VEnQ==',
      credential:
        'b4nIDOvxyFH2fO32JMilKmTxMqjtm0rmXZTwTDK309MihtTleifKSvDxTUDNPJUugTO2wMROTJ0F7DFJhnLWuw==',
      urls: ['turn:turn.halameet.com:6251?transport=tcp'],
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
