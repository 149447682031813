<div class="rate-container">
  <h1>{{ "rate.title" | translate }}</h1>
  <div class="rate">
    <h3>{{ "rate.question" | translate }}</h3>
    <p>{{ "rate.rate-call" | translate }}</p>

    <div class="stars">
      <img
        *ngFor="let _ of [].constructor(5); let i = index"
        (click)="changeStars(i)"
        [src]="
          i + 1 <= selectedRate
            ? '../../../assets/images/rate/fill-star.svg'
            : '../../../assets/images/rate/star.svg'
        "
        alt="star"
      />

      <!-- <img
        *ngFor="let _ of [].constructor(5 - selectedRate); let i = index"
        (click)="changeStars(i)"
        [src]="'../../../assets/images/rate/fill-star.svg'"
        alt="star"
      /> -->
    </div>

    <div class="text">
      <textarea
        name="test"
        id="test"
        [placeholder]="'rate.comment' | translate"
        (input)="text($event)"
      ></textarea>
    </div>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!starsFlag || disableBtn"
      (click)="submitRate()"
      class="block main-btn button18"
    >
      <span>{{ "rate.rate" | translate }}</span>
    </button>
  </div>
</div>
