<div class="side-control-toggler subtitle1">
  <a aria-label="open meeting members list" (click)="showMembers()">
    <figure>
      <mat-icon
        [ngClass]="{
          'unseen-notification': (waitingRoomFilter?.length > 0
            ? waitingRoomFilter
            : waitingRoom
          ).length
        }"
        >person</mat-icon
      >
      <figcaption
        *ngIf="
          (meetingMembersFilter?.length > 0
            ? meetingMembersFilter
            : meetingMembers
          ).length
        "
      >
        (
        {{
          viewingScreen.on
            ? (meetingMembersFilter?.length > 0
                ? meetingMembersFilter
                : meetingMembers
              ).length - 1
            : (meetingMembersFilter?.length > 0
                ? meetingMembersFilter
                : meetingMembers
              ).length
        }}
        )
      </figcaption>
    </figure>
  </a>

  <a aria-label="open chat box" (click)="showChat()">
    <mat-icon [ngClass]="{ 'unseen-notification': unreadMessages }"
      >chat</mat-icon
    >
  </a>
</div>

<div
  class="side-control"
  [ngClass]="{ collapsed: sideCollapsed, dirLtr: _lang.direction == 'ltr' }"
  *ngIf="showMembersList || showChatBox"
>
  <div class="back-meeting" (click)="toggleSide()">
    <span>{{ "waiting.back-meeting" | translate }}</span>
    <span>{{ kortobaaPkg.meetingTimer.timer }}</span>
  </div>
  <div class="side-control-header">
    <div class="header-info">
      <h4 class="subtitle2">
        {{
          (showMembersList ? "meeting-side.members" : "meeting-side.chat")
            | translate
        }}
      </h4>

      <a aria-label="close meeting side control" (click)="toggleSide()">
        <mat-icon color="primary">close</mat-icon>
      </a>
      <!-- start meeting time ago for current user -->
      <!-- <div class="timer">{{ timePassedBySeconds | timeago }}</div> -->
      <!-- end meeting time ago for current user-->
    </div>
    <div class="member-header" *ngIf="showMembersList">
      <div
        class="meeting-link"
        (click)="copyMeetingUrl(meetingInfo.token)"
        [ngClass]="{ active: copied == true }"
      >
        <figure>
          <img
            class="copy"
            src="../../../assets/images/icons/copy-icon.svg"
            [alt]="'meeting-side.meeting-link' | translate"
          />
          <img
            class="hover"
            src="../../../assets/images/icons/copy-hover-icon.svg"
            [alt]="'meeting-side.meeting-link' | translate"
          />
          <img
            class="copied"
            src="../../../assets/images/icons/copied-icon.svg"
            [alt]="'meeting-side.meeting-link' | translate"
          />
        </figure>
        <h5>
          {{ "meeting-side.meeting-link" | translate }}
        </h5>
      </div>
      <h5 class="timer" *ngIf="showMembersList">
        <!-- {{ "meeting-side.join-when" | translate }} should be changed -->
        <!-- {{ kortobaaPkg.meetingTimer.timer }} -->
        <span>
          {{
            kortobaaPkg.meetingTimer.seconds < 0 ||
            kortobaaPkg.meetingTimer.minutes < 0 ||
            kortobaaPkg.meetingTimer.hours < 0
              ? ("meeting-room.meeting-not-start" | translate)
              : kortobaaPkg.meetingTimer.timer
          }}</span
        >
      </h5>
    </div>
    <div class="member-search" *ngIf="showMembersList">
      <form class="example-form">
        <div class="form-input">
          <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="fill"
            color="primary"
          >
            <mat-icon class="material-icons-outlined"> search </mat-icon>
            <input
              matInput
              #myInput
              placeholder="{{ 'meeting-side.search' | translate }}"
              autocomplete="off"
              (input)="searchUser(myInput.value)"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </form>
      <!-- <div class="header-btns-container">
        <div class="switch-side-content">
          <button
            *ngIf="showMembersList"
            class="subtitle2"
            [ngClass]="showMembersList && 'active'"
            mat-flat-button
            (click)="showMembers()"
          >
            {{ "meeting-side.members" | translate }}
          </button>

          <mat-divider [vertical]="true"></mat-divider>

          <button
            *ngIf="showChatBox"
            class="subtitle2"
            [ngClass]="showChatBox && 'active'"
            mat-flat-button
            (click)="showChat()"
          >
            {{ "meeting-side.chat" | translate }}
            <span
              [ngClass]="{ 'unseen-notification': unreadMessages }"
              class="unread-relative"
            ></span>
          </button>
        </div>
        <button
          mat-flat-button
          class="settings-btn"
          (click)="openInvitationDialog()"
        >
          <mat-icon>person_add</mat-icon>
        </button>
      </div> -->
    </div>

    <!-- start members list -->
    <div
      *ngIf="showMembersList"
      class="members-list custom-scroll"
      [ngClass]="{ collapsed: !showMembersList }"
    >
      <!-- start waiting room list -->
      <div
        class="waiting-room-list"
        *ngIf="
          (waitingRoomFilter?.length > 0 ? waitingRoomFilter : waitingRoom)
            .length && host
        "
      >
        <div class="waiting-room-label">
          <label class="subtitle2">
            {{ "meeting-side.in-the-waiting-room" | translate }}
            ({{
              (waitingRoomFilter?.length > 0 ? waitingRoomFilter : waitingRoom)
                .length
            }})
          </label>
          <button
            mat-button
            class="admit-all-btn"
            (click)="acceptAll()"
            *ngIf="
              (waitingRoomFilter?.length > 0 ? waitingRoomFilter : waitingRoom)
                .length > 1
            "
          >
            {{ "meeting-side.accept-all" | translate }}
          </button>
        </div>
        <div
          class="member"
          *ngFor="
            let member of waitingRoomFilter?.length > 0
              ? waitingRoomFilter
              : waitingRoom
          "
        >
          <div style="display: flex; align-items: center">
            <div class="user-img-container">
              <img
                [src]="member.image"
                [alt]="member.name"
                class="user-img img"
                ployfillImg
              />
            </div>

            <p class="member-name bodytext-normal">
              {{ member.name }}
              <span class="subtitle1">
                {{ "meeting-side.guest" | translate }}
              </span>
            </p>
          </div>
          <div class="waiting-room-actions">
            <a
              (click)="acceptMemeber(member.userId, true)"
              class="accept-action"
            >
              <mat-icon>check</mat-icon>
            </a>

            <a
              (click)="acceptMemeber(member.userId, false)"
              class="reject-action"
              (click)="rejectMemeber(member.userId)"
            >
              <mat-icon>close</mat-icon>
            </a>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>
      <!-- end waiting room list -->

      <!-- NOTE: DONNOT remove (UI without logic) -->
      <!-- start room list search -->
      <!-- <div class="search-member-container">
        <mat-form-field
          class="bodytext-normal"
          block
          appearance="filled"
          color="primary"
        >
          <input
            type="text"
            placeholder="{{ 'meeting-side.search' | translate }}"
            name="meetingID"
            #meetingInput
            matInput
            required
          />
          <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>

        <div>
          <mat-icon
            [matMenuTriggerFor]="searchMember"
            class="search-member-icon menu-icon"
          >
            sync_alt
          </mat-icon>
          <mat-menu
            #searchMember="matMenu"
            class="meeting-menu search-member-menu"
          >
            <div class="menu-content">
              <button class="subtitle2 close-btn" mat-menu-item>
                <mat-icon>close</mat-icon>
                <span> {{ "meeting-side.sort-by" | translate }}</span>
              </button>

              <mat-divider></mat-divider>
              <button class="subtitle2" mat-menu-item>
                {{ "meeting-side.raise-hand" | translate }}
                (2)
              </button>

              <button class="subtitle2" mat-menu-item>
                {{ "meeting-side.name" | translate }}
              </button>
            </div>
          </mat-menu>
        </div>
      </div> -->
      <!-- end room list search -->

      <!-- start room list header -->
      <div class="list-header">
        <label class="subtitle2">
          {{ "meeting-side.members-num" | translate }}
          ({{
            (meetingMembersFilter?.length > 0
              ? meetingMembersFilter
              : meetingMembers
            ).length
          }})
        </label>

        <button
          class="subtitle1"
          *ngIf="host && !muteUnAll"
          mat-flat-button
          (click)="openMuteAllDialog()"
        >
          {{ "meeting-side.mute-all" | translate }}
        </button>

        <button
          class="subtitle1"
          *ngIf="host && muteUnAll"
          mat-flat-button
          (click)="unMuteAll()"
        >
          {{ "meeting-side.unmute-all" | translate }}
        </button>
      </div>
      <!-- end room list header -->

      <!-- start room list members-->
      <ng-container
        *ngFor="
          let member of meetingMembersFilter?.length > 0
            ? meetingMembersFilter
            : meetingMembers;
          let i = index
        "
      >
        <div class="member" *ngIf="member.type != 'screen'">
          <div style="display: flex; align-items: center">
            <div
              class="user-img-container"
              [ngStyle]="{
                border: member.host ? '.8px solid var(--primary-color)' : ''
              }"
            >
              <img
                [src]="member.image"
                ployfillImg
                [alt]="member.name"
                class="user-img img"
              />
              <img
                *ngIf="member?.isPinned!"
                class="pinned-user"
                src="assets/images/pinned-sidebar.svg"
                alt="pinned"
              />
            </div>

            <p class="member-name bodytext-normal">
              {{ member.name }}
              <span *ngIf="member.host" class="subtitle1">
                {{ "meeting-side.host" | translate }}
                <!-- <span *ngIf="member.id == userId">{{
                  "meeting-side.me" | translate
                }}</span> -->
              </span>
              <span *ngIf="!member.host" class="subtitle1">
                <ng-container *ngIf="member?.isGuest">{{
                  "meeting-side.guest" | translate
                }}</ng-container>
                <ng-container *ngIf="!member?.isGuest">{{
                  "meeting-side.guest" | translate
                }}</ng-container>
                <!-- <span *ngIf="member.id == userId">{{
                  "meeting-side.me" | translate
                }}</span> -->
              </span>
            </p>
          </div>
          <div
            class="mic-status"
            [ngClass]="{ host: host }"
            *ngIf="member.type != 'screen'"
          >
            <mat-icon
              [ngClass]="{ 'host-icon': host }"
              *ngIf="!member.video"
              class="cam-off-icon"
              >videocam_off
            </mat-icon>

            <mat-icon
              [ngClass]="{ 'host-icon': host }"
              *ngIf="member.video"
              class="cam-on-icon"
              >videocam
            </mat-icon>

            <!-- start mic status for guest only -->
            <mat-icon
              *ngIf="!host && !member.audio"
              class="mic-off-icon material-icons-outlined"
              >mic_off</mat-icon
            >

            <mat-icon
              *ngIf="!host && member.audio && !member.activeSpeaker"
              class="mic-on-icon material-icons-outlined"
              >mic_none
            </mat-icon>

            <mat-icon
              *ngIf="!host && member.audio && member.activeSpeaker"
              class="active-speaker-icon material-icons-outlined"
              >mic
            </mat-icon>
            <!-- end mic status for guest only -->

            <!-- start mic actions for host only -->
            <!--  -->
            <mat-icon
              matTooltipClass="tooltip-control"
              #tooltip="matTooltip"
              (click)="unMuteMember(member)"
              [matTooltip]="
                member.host ? '' : ('meeting-room.cant-mute' | translate)
              "
              matTooltipPosition="above"
              *ngIf="host && !member.audio"
              class="mic-off-icon host-icon material-icons-outlined"
              >mic_off
            </mat-icon>

            <mat-icon
              (click)="openMuteMemberDialog(member)"
              *ngIf="host && member.audio && !member.activeSpeaker"
              class="mic-on-icon host-icon material-icons-outlined"
              >mic_none</mat-icon
            >

            <mat-icon
              (click)="muteMember(member)"
              *ngIf="host && member.audio && member.activeSpeaker"
              class="active-speaker-icon host-icon material-icons-outlined"
              >mic</mat-icon
            >
            <!-- end mic actions for host only -->

            <!-- <mat-icon
              [ngClass]="{ 'host-icon': host }"
              *ngIf="!member.video"
              class="cam-off-icon"
              >videocam_off
            </mat-icon>

            <mat-icon
              [ngClass]="{ 'host-icon': host }"
              *ngIf="member.video"
              class="cam-on-icon"
              >videocam
            </mat-icon> -->

            <!--  start host action dropdown -->
            <mat-icon
              [ngStyle]="{
                visibility: host && member.id != userId ? 'visible' : 'visible'
              }"
              #menuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menu"
              class="host-icon menu-icon"
              >more_vert</mat-icon
            >
            <mat-menu #menu="matMenu" class="meeting-menu host-actions-menu">
              <!-- <button
                class="subtitle2"
                mat-menu-item
                *ngIf="!member.audio"
                (click)="unMuteMember(member)"
              >
                {{ "meeting-side.unmute" | translate }}
              </button>
              <button
                class="subtitle2"
                mat-menu-item
                *ngIf="member.audio"
                (click)="muteMember(member)"
              >
                {{ "meeting-side.mute" | translate }}
              </button> -->

              <div class="menu-content">
                <!-- <button class="subtitle2 close-btn" mat-menu-item>
                    <mat-icon>close</mat-icon>
                  </button> -->

                <!-- <button class="subtitle2" mat-menu-item>
                  {{ "meeting-side.rename" | translate }}
                </button>
                <mat-divider></mat-divider> -->

                <button
                  class="subtitle2"
                  mat-menu-item
                  (click)="pinParticipant(member.id, i)"
                  *ngIf="!member?.isPinned!"
                >
                  <img
                    src="'../../../assets/images/pin-menu.svg"
                    [alt]="'meeting-side.pin' | translate"
                  />
                  <img
                    class="hover"
                    src="'../../../assets/images/pin-menu-hover.svg"
                    [alt]="'meeting-side.pin' | translate"
                  />

                  {{ "meeting-side.pin" | translate }}
                </button>

                <button
                  class="subtitle2"
                  mat-menu-item
                  (click)="unpinParticipant(member.id, i)"
                  *ngIf="member?.isPinned!"
                >
                  <img
                    src="'../../../assets/images/unpin-menu.svg"
                    [alt]="'meeting-side.pin' | translate"
                  />
                  <img
                    class="hover"
                    src="'../../../assets/images/unpin-menu-hover.svg"
                    [alt]="'meeting-side.pin' | translate"
                  />

                  {{ "meeting-side.unpin" | translate }}
                </button>

                <button
                  class="subtitle2"
                  *ngIf="!member.host && kortobaaPkg.isHost"
                  mat-menu-item
                  (click)="assignHost(member.id)"
                >
                  <img
                    src="'../../../assets/images/icons/Guest.svg"
                    [alt]="'meeting-side.make-host' | translate"
                  />
                  <img
                    class="hover"
                    src="'../../../assets/images/icons/Guest-hover.svg"
                    [alt]="'meeting-side.make-host' | translate"
                  />

                  {{ "meeting-side.make-host" | translate }}
                </button>
                <!-- NOTE: DONNOT remove (Postponed features) -->
                <!-- <mat-divider></mat-divider> -->
                <!--
                <button class="subtitle2" mat-menu-item>
                  {{ "meeting-side.pin" | translate }}
                </button> -->

                <ng-container *ngIf="!member.host && kortobaaPkg.isHost">
                  <mat-divider></mat-divider>
                  <button
                    class="subtitle2 delete-text-menu"
                    mat-menu-item
                    (click)="openRemoveUserDialog(member)"
                  >
                    <img
                      src="'../../../assets/images/person_remove.svg"
                      [alt]="'meeting-side.make-host' | translate"
                    />
                    <img
                      class="hover"
                      src="'../../../assets/images/person_remove-hover.svg"
                      [alt]="'meeting-side.make-host' | translate"
                    />
                    {{ "meeting-side.remove-from-meeting" | translate }}
                  </button>
                </ng-container>
              </div>
            </mat-menu>
            <!--  end host action dropdown -->
          </div>
        </div>
      </ng-container>
      <!-- end room list members-->

      <!-- NOTE: DONNOT remove (UI without logic) -->
      <!-- start room list actions-->
      <!-- <ng-container *ngIf="host">
        <mat-icon
          [matMenuTriggerFor]="roomListActions"
          class="room-list-actions-icon menu-icon"
        >
          more_horiz
        </mat-icon>
        <mat-menu
          #roomListActions="matMenu"
          class="meeting-menu meeting-actions-menu"
        >
          <div class="menu-content">
            <mat-checkbox
              class="subtitle2"
              color="accent"
              name="muteParticipants"
            >
              {{ "account.mute-members-upon-join" | translate }}
            </mat-checkbox>
            <mat-divider></mat-divider>
            <mat-checkbox
              class="subtitle2"
              color="accent"
              name="muteParticipants"
            >
              {{ "account.allow-to-revoice" | translate }}
            </mat-checkbox>
          </div>
        </mat-menu>
      </ng-container> -->
      <!-- end room list actions-->
    </div>
    <!-- end members list -->

    <!-- start chat box -->
    <div
      *ngIf="showChatBox"
      [dir]="direction"
      class="chat-container"
      [ngClass]="{ collapsed: !showChatBox }"
    >
      <!-- <div class="no-messages subtitle2" *ngIf="!chat.length">
          <mat-icon>speaker_notes_off</mat-icon>
          <p>{{ "meeting-side.no-messages" | translate }}</p>
        </div> -->
      <div class="chat-alert-msg">
        <p>{{ "meeting-side.chat-intro" | translate }}</p>
      </div>
      <div
        *ngIf="chat.length"
        class="chat-body custom-scroll"
        #chatBody
        [scrollTop]="chatBody.scrollHeight"
      >
        <!-- <div class="chat-alert-msg">
            <p>{{ "meeting-side.chat-intro" | translate }}</p>
          </div> -->
        <div *ngFor="let message of chat; index as i">
          <!-- Don't render if sender is the current user -->
          <div
            class="message"
            [ngClass]="{
              'active-user': message.user == 'me',
              dirLtr: _lang.direction == 'ltr'
            }"
          >
            <figure
              class="user-img-container"
              *ngIf="message.user != 'me'"
              [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
              [ngStyle]="{
                visibility:
                  (i == 0 && chat[i]?.sender != chat[i + 1]?.sender) ||
                  i == chat.length - 1 ||
                  (chat[i]?.sender == chat[i - 1]?.sender &&
                    chat[i]?.sender != chat[i + 1]?.sender) ||
                  (chat[i - 1]?.sender == chat[i + 1]?.sender &&
                    chat[i]?.sender != chat[i + 1]?.sender)
                    ? 'visible'
                    : 'hidden'
              }"
            >
              <!-- [src]="message.image" -->
              <img
                src="assets/images/no-img-light.svg"
                ployfillImg
                alt=""
                class="user-img img"
              />
            </figure>
            <div
              class="message-text"
              [ngClass]="{
                dirLtr: _lang.direction == 'ltr',
                lastMsg:
                  (i == 0 && chat[i]?.sender != chat[i + 1]?.sender) ||
                  i == chat.length - 1 ||
                  (chat[i]?.sender == chat[i - 1]?.sender &&
                    chat[i]?.sender != chat[i + 1]?.sender) ||
                  (chat[i - 1]?.sender == chat[i + 1]?.sender &&
                    chat[i]?.sender != chat[i + 1]?.sender)
              }"
            >
              <div></div>
              <p class="bodytext-normal">
                <span
                  class="chat-assets"
                  [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
                  [ngStyle]="{
                    display: message.content.length > 15 ? 'block' : 'flex'
                  }"
                >
                  <span
                    [dir]="direction"
                    [outerHTML]="message.content | matchUrl"
                  ></span>
                  <div>
                    <img
                      *ngIf="message.user == 'me'"
                      src="../../../assets/images/icons/done_all.svg"
                    />
                    <span class="time caption">{{ message.date }}</span>
                  </div>
                </span>
              </p>
            </div>
          </div>
          <span
            class="subtitle1 sender-name"
            *ngIf="message.user != 'me'"
            [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
            >{{
              (message.user != "me" &&
                i == 0 &&
                chat[i]?.sender != chat[i + 1]?.sender) ||
              i == chat.length - 1 ||
              (chat[i]?.sender == chat[i - 1]?.sender &&
                chat[i]?.sender != chat[i + 1]?.sender) ||
              (chat[i - 1]?.sender == chat[i + 1]?.sender &&
                chat[i]?.sender != chat[i + 1]?.sender)
                ? message.sender
                : ""
            }}</span
          >
        </div>
      </div>
      <form
        action=""
        class="type-message"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <div class="chat-input">
          <textarea
            type="text"
            placeholder="{{ 'meeting-side.write-message' | translate }}"
            id="newMessage"
            (keyup.enter)="sendMessage()"
            (keypress)="isMaximum($event)"
            [(ngModel)]="newMessage"
            [ngModelOptions]="{ standalone: true }"
            autocomplete="off"
            required
            maxlength="1000"
            class="bodytext-normal"
          ></textarea>
          <span class="text-length">{{ newMessage.length }} / 1000</span>
          <button
            class="emoji-btn"
            (click)="isEmojiPickerVisible = !isEmojiPickerVisible"
          >
            <img src="../../../assets/images/icons/emoji.png" alt="" />
          </button>
          <br /><emoji-mart
            class="emoji-mart"
            *ngIf="isEmojiPickerVisible"
            (emojiSelect)="addEmoji($event)"
            title="Choose your emoji"
          ></emoji-mart>
        </div>
        <a
          class="send-btn"
          (click)="sendMessage()"
          [ngClass]="{
            disabled: !newMessage,
            dirLtr: _lang.direction == 'ltr'
          }"
        >
          <figure>
            <img
              src="../../../assets/images/icons/send.svg"
              [alt]="'meeting-side.send-message' | translate"
            />
          </figure>
        </a>
      </form>
    </div>
    <!-- end chat box -->
  </div>
</div>
