<div class="spinner" *ngIf="!dataService.dialogOpen">
  <mat-spinner diameter="50"></mat-spinner>
</div>

<div
  class="loading"
  *ngIf="dataService.loading || dataService.recordingLoading"
  [ngClass]="{'main-container': dataService.url != '/login','ar-font':this.lang.currentLang == 'ar'}"
  [dir]="this.lang.direction"
>
  <div class="logo">
    <app-spinner></app-spinner>
    <!-- <img src="assets/images/logo.svg" /> -->
    <p>
      {{(dataService.loading ? 'Loading' : 'meeting-room.recording-loading') |
      translate }}
    </p>
  </div>
</div>
<div
  [ngClass]="{'main-container': dataService.url != '/login','ar-font':this.lang.currentLang == 'ar'}"
  [dir]="this.lang.direction"
>
  <app-header></app-header>
</div>
