import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserService } from '@kortobaa-front/authentication';
import { UsersService } from 'src/app/auth/services/users.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ServerService } from 'src/app/shared/services/server.service';
import { Country } from 'src/app/shared/models/interfaces';
import { replaceTemplate } from 'src/app/shared/helpers/customFunctions';
import { NgForm } from '@angular/forms';
import { LangService } from 'src/app/shared/services/lang.service';
import { scrollRequired } from 'src/app/shared/services/scrollRequired.service';

export interface ResendCredentials {
  reset: string;
  type: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ServerService],
})
export class LoginComponent implements OnInit {
  usernamePattern =
    /^(?:[a-zA-Z0-9\s,\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){1,30}$/;

  isloginPasswordHidden = true;
  isSignUpPasswordHidden = true;
  isSignUpConfrimPasswordHidden = true;
  loading = false;
  signUpForm = false;
  resetPassForm = false;
  verifyPassForm = false;
  newPassForm = false;
  countries: Country[] = null;
  uuid?: string;

  capsOn = false;
  wrongPassCriteria = false;
  loginFailed = false;
  userData = {
    email: '',
    password: '',
    rememberMe: true,
  };

  registerData = {
    email: '',
    password: '',
    passConfirmation: '',
    userName: '',
    phone: '',
    additionalData: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? '',
    },
  };

  loginResponseData = {
    errorNotification: '',
    errorFromserver: '',
    waitingTime: '',
    attempts: 0,
    attempText: '',
  };

  signUpResponseData = {
    errorNotification: '',
    errorFromserver: '',
    waitingTime: '',
    attempts: 0,
    attempText: '',
  };

  resetPassData = {
    resetEmail: '',
    verificationCode: '',
  };

  mediaSub: Subscription;
  resetPassSub$!: Subscription;
  verifyPassSub$!: Subscription;

  deviceXS: boolean;

  @ViewChild('passwordForm') passwordForm: NgForm;
  @ViewChild('loginForm') loginForm!: NgForm;
  @ViewChild('registerForm') registerForm!: NgForm;
  @ViewChild('registerScroll') registerScroll!: any;

  isConfrimPasswordHidden = true;
  isNewPasswordHidden = true;

  passFormData = {
    newPassword: '',
    passConfirmation: '',
  };

  public screenWidth: any = window.innerWidth;
  isRsendOTP: boolean = true;
  resendDuration: number = 60;
  otpData!: any;
  durationInterval!: any;

  passwordError = {
    length: false,
    english: false,
    number: false,
    special: false,
    upper: false,
    spaces: false,
  };

  passwordhasErrors = false;

  constructor(
    public mediaObserver: MediaObserver,
    public cdRef: ChangeDetectorRef,
    private _dataService: DataService,
    private _userService: UsersService,
    private _countriesService: CountriesService,
    private _translate: TranslateService,
    private _router: Router,
    public _lang: LangService,
    private _kortobaaPkgUserService: UserService,
    private _scrollRequired: scrollRequired
  ) {}

  ngOnInit() {
    // Active signup form if route is '/signup'
    this.signUpForm = this._router.url == '/signup';

    // window.addEventListener('scroll', this.scroll, true);

    // this.mediaSub = this.mediaObserver.media$.subscribe(
    //   (change: MediaChange) => {}
    // );

    this.setCountriesList();
  }

  // a switcher function between resend login otp, register otp and forget-password otp
  async resendOTP(firstTime?: boolean) {
    this.isRsendOTP = false;
    this.durationInterval = await setInterval(() => {
      if (this.resendDuration > 1) {
        --this.resendDuration;
      } else {
        this.resendDuration = 60;
        this.isRsendOTP = !this.isRsendOTP;
        clearInterval(this.durationInterval);
      }
    }, 1000);
    if (!firstTime) {
      this.sendVerificationCode(true);
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  setCountriesList() {
    this._countriesService.getCountriesList().subscribe((countriesList) => {
      this.countries = countriesList;
    });
  }

  async onLoginSubmit() {
    if (
      this.loginForm &&
      this.loginForm.form &&
      this.loginForm.form.status == 'INVALID'
    ) {
      return this._scrollRequired.scrollToFirstInvalidControl(
        'loginForm',
        this.loginForm.form.controls
      );
    }
    this.loginResponseData.attempts = 0;
    this.loginResponseData.waitingTime = null;
    this.loginResponseData.errorFromserver = '';
    this.loginResponseData.errorNotification = '';
    this.loading = true;
    let message: string;
    try {
      await this._userService.login({
        login: this.userData.email.toLowerCase(),
        password: this.userData.password,
        rememberMe: this.userData.rememberMe,
      });

      const { username } = this._kortobaaPkgUserService.currentUser;

      message = replaceTemplate(
        this._translate.instant('snackbar.welcome'),
        {}
      );
      // message = replaceTemplate(
      //   this._translate.instant('snackbar.welcome-username'),
      //   { USER_NAME: username }
      // );
      this._dataService.notification(message);
      this._router.navigate(['home']);
    } catch (error) {
      console.log(error.status);
      this.loginFailed = true;
      if (error.status == 401) {
        message = this._translate.instant('errors.invalid-email-or-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      // this._dataService.notification(message, true);
    }
    this.loading = false;
  }

  checkPasswordPattern(password: string) {
    this.registerData.password.trim();
    this.passFormData.newPassword.trim();
    const patternUpper = RegExp('[A-Z]');
    const patternNumber = RegExp('[0-9]');
    const patternSpecial = RegExp('[_+#?!@$%^&*-]');
    const patternEnglish = RegExp('[a-z]|[A-Z]');
    const patternSpace = RegExp('^[^\n ]*$');

    this.passwordError = {
      length: password.length >= 8 ? true : false,
      upper: patternUpper.test(password),
      english: patternEnglish.test(password),
      number: patternNumber.test(password),
      special: patternSpecial.test(password),
      spaces: patternSpace.test(password),
    };
    this.passwordhasErrors = Object.values(this.passwordError).includes(false);
  }

  async onSignUpSubmit() {
    // if any field required scroll to it
    if (
      this.registerForm &&
      this.registerForm.form &&
      this.registerForm.form.status == 'INVALID'
    ) {
      return this._scrollRequired.scrollToFirstInvalidControl(
        'registerForm',
        this.registerForm.form.controls
      );
    }
    this.signUpResponseData.attempts = 0;
    this.signUpResponseData.waitingTime = null;
    this.signUpResponseData.errorFromserver = '';
    this.signUpResponseData.errorNotification = '';
    this.loading = true;
    if (this.registerData.password != this.registerData.passConfirmation) {
      this.loading = false;
      const msg = this._translate.instant('validation.pass-not-match');
      this._dataService.notification(msg, true);
      return;
    }

    let message: string;
    try {
      const filteredAdditionalData = { ...this.registerData.additionalData };

      // Remove empty additional data
      for (let key in filteredAdditionalData) {
        if (
          filteredAdditionalData.hasOwnProperty(key) &&
          filteredAdditionalData[key] === ''
        ) {
          delete filteredAdditionalData[key];
        }
      }

      const signUpdata = {
        email: this.registerData.email.toLowerCase(),
        password: this.registerData.password.trim(),
        // username: `${filteredAdditionalData.firstName} ${filteredAdditionalData.lastName}`,
        username: `${filteredAdditionalData.firstName.trimStart().trimEnd()}`,
        phone: this.registerData.phone,
        additionalData: filteredAdditionalData,
      };

      // TODO: remove the workaround in the signup after change the Register interface in @kortobaa-front/authentication
      await this._userService.signUp(signUpdata as any);
      message = this._translate.instant('login.registered-successfully');
      this._dataService.notification(message);
      this.loading = false;
      this.registerForm.resetForm();
      this.toggleSignUpForm();
    } catch (error) {
      this.loading = false;
      if (error.status == 401) {
        message = this._translate.instant('errors.invalid-email-or-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this._dataService.notification(message, true);
    }
  }

  toggleDisplayPassword(e: PointerEvent, inputName: string) {
    e.stopPropagation();
    switch (inputName) {
      case 'loginPassword':
        return (this.isloginPasswordHidden = !this.isloginPasswordHidden);

      case 'signUpPassword':
        return (this.isSignUpPasswordHidden = !this.isSignUpPasswordHidden);

      case 'signUpConfrimPassword':
        return (this.isSignUpConfrimPasswordHidden =
          !this.isSignUpConfrimPasswordHidden);

      case 'confrimPassword':
        return (this.isConfrimPasswordHidden = !this.isConfrimPasswordHidden);

      case 'newPasswordHide':
        return (this.isNewPasswordHidden = !this.isNewPasswordHidden);
    }
  }

  toggleSignUpForm() {
    this.signUpForm = !this.signUpForm;
  }

  resetPassword() {
    this.resetPassForm = true;
  }

  async sendVerificationCode(resendCode?: boolean) {
    let resetPassData;
    let message: string;
    this.loading = true;

    try {
      resetPassData = await this._userService.sendVerificationCode(
        this.resetPassData.resetEmail.toLowerCase()
      );
      this.uuid = resetPassData.secret;
      this.loading = false;

      if (!resendCode) {
        this.resetPassForm = false;
        this.verifyPassForm = true;
        this.resendOTP(true);
      }
    } catch (error) {
      this.loading = false;

      if (error.status == 401) {
        message = this._translate.instant('errors.invalid-email-or-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this._dataService.notification(message, true);
    }
  }

  validateResetCode() {
    let resetPassData;
    let message: string;
    this.loading = true;
    // try {
    //   this.loading = true;
    //   resetPassData = await this._userService.validateResetCode(
    //     this.resetPassData.verificationCode
    //   );

    //   if (this._translate.currentLang === 'ar')
    //     message = `تم إرسال كلمة مرور جديدة إلى بريدك الإلكتروني`;
    //   if (this._translate.currentLang === 'en')
    //     message = `A new password has been sent to your email`;
    //   this._dataService.notification(message);

    //   this.verifyPassForm = false;
    // } catch (error) {
    //   if (error.status == 401) {
    //     message = this._translate.instant('errors.invalid-email-or-pass');
    //   } else if (error.status == 400 || error.status == 422) {
    //     message = this._translate.instant(error.error.error.message);
    //   } else {
    //     message = this._translate.instant(error.message);
    //   }

    //   this._dataService.notification(message, true);
    // }

    this.verifyPassSub$ = this._userService
      .verifyResetPassword(this.resetPassData.verificationCode, this.uuid)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.verifyPassForm = false;
          this.newPassForm = true;
        },
        error: (error) => {
          this.loading = false;

          if (error.status == 401) {
            message = this._translate.instant('errors.invalid-email-or-pass');
          } else if (error.status == 400 || error.status == 422) {
            message = this._translate.instant(error.error.error.message);
          } else {
            message = this._translate.instant(error.message);
          }

          this._dataService.notification(message, true);
        },
      });
  }

  // scroll() {
  //   const inputFields = document.getElementsByTagName('input');
  //   for (let input of inputFields) {
  //     input.blur();
  //   }
  // }

  changeStyle() {
    if (
      this.registerScroll.nativeElement.children[1].children[0].scrollTop > 0
    ) {
      this.registerScroll.nativeElement.children[0].style.flexDirection = 'row';
      this.registerScroll.nativeElement.children[0].children[1].style.fontSize =
        '1.5rem';
    } else {
      this.registerScroll.nativeElement.children[0].style.flexDirection =
        'column';
      this.registerScroll.nativeElement.children[0].children[1].style.fontSize =
        '1.75rem';
    }
  }

  updateUserPassword() {
    let message: string;
    this.loading = true;

    let body = {
      token: this.resetPassData.verificationCode,
      uuid: this.uuid,
      newPassword: this.passFormData.newPassword,
    };

    this.resetPassSub$ = this._userService.resetPassword(body).subscribe({
      next: (data) => {
        message = this._translate.instant(
          'account.resset-password-successfully'
        );

        this._dataService.notification(message);
        // Reset passwords fields
        this.passwordForm.resetForm();
        this.cancel();
        this.newPassForm = false;
        this.loading = false;
      },
      error: (error) => {
        if (error.status == 401) {
          message = this._translate.instant('errors.invalid-pass');
        } else if (error.status == 400 || error.status == 422) {
          message = this._translate.instant(error.error.error.message);
        } else {
          message = this._translate.instant(error.message);
        }
        this._dataService.notification(message, true);
        this.loading = false;
      },
    });
  }

  // cancel and go back
  cancel(value?: string) {
    this.resendDuration = 60;
    this.resetPassData.verificationCode = '';
    clearInterval(this.durationInterval);
    if (!value) this.resetPassData.resetEmail = '';
  }

  ngOnDestroy() {
    if (this.mediaSub) {
      this.mediaSub.unsubscribe();
    }
    if (this.resetPassSub$) this.resetPassSub$.unsubscribe();
    if (this.verifyPassSub$) this.verifyPassSub$.unsubscribe();
  }
}
