/*https://socket.io/docs/emit-cheatsheet/*/
module.exports = [
  //'error',
  'connect',
  'disconnect',
  'disconnecting',
  'newListener',
  'removeListener',
  'ping',
  'pong'
];
