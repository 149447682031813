import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '@kortobaa-front/authentication';

import { DataService } from 'src/app/shared/services/data.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { NotificationsDialogComponent } from 'src/app/meeting/notifications-dialog/notifications-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout-component.html',
  styleUrls: ['./layout-component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public lang: LangService,
    private dialog: MatDialog,
    private _route: Router,
    private _usersService: UserService
  ) {}

  ngOnInit() {
    this.watchTokenValidity();
  }

  watchTokenValidity() {
    this._usersService.isLogout$.subscribe(async (isLogout) => {
      const isEnterMeeting = this._route.url.startsWith('/meeting');

      // If the token and the refresh token are expired and user not enter meeting page
      if (isLogout?.isLoggedOut && !isEnterMeeting) {
        // Go to home page
        // this._route.navigate(['']);
        // Notify the user that the session is expired and should login again
        // const toLoginPage = await this.dialog
        //   .open(NotificationsDialogComponent, {
        //     data: { msg: 'your-session-has-expired', confirmMsg: 're-login' },
        //     width: '26rem',
        //     direction: this.lang.direction,
        //     disableClose: true,
        //   })
        //   .afterClosed()
        //   .toPromise();
        // // If user choose to login, go to login page, else stay on home page
        // if (toLoginPage) this._route.navigate(['login']);
      }
      // If the token and the refresh token are expired and user enter meeting page
      else if (isLogout?.isLoggedOut && isEnterMeeting) {
        // this.dataService.notification('your-session-has-expired', true);
      }
    });
  }
}
