import { Injectable } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  direction: Direction = null;
  currentLang: string;

  videoDisabled = false;

  constructor(private _translate: TranslateService) {
    this._setInitLang();
    this.currentLang = this._translate.currentLang;
  }

  private _setInitLang() {
    // Get the default language and direction or set it to english and ltr
    const savedLanguage = localStorage.getItem('defaultLanguage');
    this.direction = (localStorage.getItem('dir') as Direction) || 'rtl';

    this._translate.addLangs(['en', 'ar']);
    if (savedLanguage) {
      this._translate.setDefaultLang(savedLanguage);
      this._translate.use(savedLanguage);
    } else {
      this._translate.setDefaultLang('ar');
      this._translate.use('ar');
    }
  }

  useLanguage(language: string) {
    if (language == 'ar') this.direction = 'rtl';
    else this.direction = 'ltr';

    // Set the default language and direction
    localStorage.setItem('dir', this.direction);
    localStorage.setItem('defaultLanguage', language);

    this._translate.use(language);
    this.currentLang = language;
  }
}
