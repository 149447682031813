<div
  class="dialog"
  [dir]="lang.direction"
  [ngClass]="{ dirLtr: lang.direction == 'ltr' }"
>
  <div class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>

  <h3>{{ "privacy.reject-priv" | translate }}</h3>

  <p>
    {{ "privacy.reject-details" | translate }}
  </p>

  <div class="btns">
    <button
      (click)="close()"
      mat-raised-button
      class="block main-btn button18 white"
    >
      <span>{{ "privacy.cancel" | translate }}</span>
    </button>

    <button
      (click)="confirm()"
      mat-raised-button
      class="block main-btn button18 danger"
    >
      <span>{{ "privacy.confirm" | translate }}</span>
    </button>
  </div>
</div>
