import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrivacyRejectComponent } from '../privacy-reject/privacy-reject.component';
import { SocketService } from '../services/socket.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/shared/services/lang.service';

@Component({
  selector: 'app-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.scss'],
})
export class CloseDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PrivacyRejectComponent>,
    private socketService: SocketService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public lang: LangService
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  confirm() {
    if (this.data.type == 'mute') {
      this.dialogRef.close(true);
      return;
    }

    if (this.data.type && this.data.type == 'leave') {
      const data = {
        callId: this.socketService.id,
      };
      this.socketService.emitEvent('leaveCall', data);
    }
    this.socketService.disconnect();
    if (this.iOS() || navigator.userAgent.includes('Firefox')) {
      window.location.href = environment.REDIRECT_URL;
    } else {
      window.close();
    }
  }
}
