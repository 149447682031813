import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import { UserService } from '@kortobaa-front/authentication';
import {
  KortobaaMeetService,
  MeetingEventsListenersService,
  UserMediaService,
} from '@kortobaa-front/ng-kmeet-sdk';
import { DataService } from 'src/app/shared/services/data.service';
import { UsersService } from 'src/app/auth/services/users.service';
import { ServerService } from 'src/app/shared/services/server.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { prepareName } from 'src/app/shared/helpers/customFunctions';
import { AllowMediaComponent } from 'src/app/meeting/allow-media/allow-media.component';
import { environment } from 'src/environments/environment';
import { WebrtcService } from 'src/app/shared/services/webrtc.service';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { MeetingsService } from '../services/meetings.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { TranslateService } from '@ngx-translate/core';
import { PrivacyRejectComponent } from '../privacy-reject/privacy-reject.component';
import { SocketService } from '../services/socket.service';
import { CloseDialogComponent } from '../close-dialog/close-dialog.component';
import { RateService } from '../rate-call/rate.service';
import { take } from 'rxjs/operators';
import * as SoundMeter from 'src/assets/js/soundmeter';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss'],
  providers: [
    ServerService,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'customPanel' },
    },
  ],
})
export class ConfigDialogComponent implements OnInit, OnDestroy {
  confirmPattern =
    /^(?:[a-zA-Z0-9\s,\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,25}$/;
  browserCompatibility: boolean = true;
  guestName: string = '';
  guestLoginForm: boolean = false;
  guestRestorePassForm: boolean = false;
  guestNewPassForm: boolean = false;
  otpConfirmForm: boolean = false;
  hide: boolean = true;
  hideSignup = true;
  hideConfirm = true;
  isUDPConnected = false;
  userData = {
    email: '',
    password: '',
    sentCode: '',
    newPass: '',
    otp: '',
    otpId: '',
    rememberMe: true,
  };
  //diaply errors
  errorNotification = '';
  waitingTime;
  attempts = 0;
  capsOn = false;
  attempText = '';
  //for loading buttons
  loading = {
    login: false,
    getData: true,
  };
  //to check if user in mobile
  openAppUi = false;
  allow = {
    micImg: 'assets/icons/mic_off.svg',
    micMessage: 'mic-blocked',
    camImg: 'assets/icons/videocam_off.svg',
    camMessage: 'camera-blocked',
  };
  protocolType = 'Nothing';
  private _latestUnsupportedVersion = 83;
  private _checkUserValidSub: Subscription;

  meetingUrl: any;
  meetingTitle: string;

  waitingScreen = false;
  privacyAccepted = false;
  currnetQueue = 0;

  registerData = {
    email: '',
    password: '',
    passConfirmation: '',
    userName: '',
    phone: '',
    additionalData: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      country: '',
    },
  };

  wrongPassCriteria = false;
  passwordError = {
    length: false,
    english: false,
    number: false,
    special: false,
    upper: false,
  };

  // Sound meter
  fps = 20;
  now;
  then = Date.now();
  interval = 1000 / this.fps;
  delta;
  drawTimer;
  soundMeter = new SoundMeter(window);
  inputLevel: number = 3; // Shoud be a number from 1 to 10
  inputLevels = [...Array(50).keys()];

  resetMeter() {
    //Stop
    cancelAnimationFrame(this.drawTimer);
    let _this = this;
    function draw() {
      _this.drawTimer = requestAnimationFrame(draw);

      _this.now = Date.now();
      _this.delta = _this.now - _this.then;

      if (_this.delta > _this.interval) {
        _this.then = _this.now;
        var tot = Math.min(100, _this.soundMeter.instant * 200);
        _this.inputLevel = Number((tot / 10) * 5);

        //Get all
        /*const voometers:any = document.querySelectorAll (".voometer-input");
         //Set new size
         for (let i=0;i<voometers.length;++i)
           voometers[i].style.width = (Math.floor(tot/5)*5) + "%";*/
      }
    }
    this.soundMeter
      .connectToSource(this.userMediaService.audioStream)
      .then(draw);
  }

  checkPasswordPattern(password: string) {
    const patternUpper = RegExp('[A-Z]');
    const patternNumber = RegExp('[0-9]');
    const patternSpecial = RegExp('[_+#?!@$%^&*-]');
    const patternEnglish = RegExp('[a-z]|[A-Z]');

    this.passwordError = {
      length: password.length >= 8 ? true : false,
      upper: patternUpper.test(password),
      english: patternEnglish.test(password),
      number: patternNumber.test(password),
      special: patternSpecial.test(password),
    };
  }

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dataService: DataService,
    public userMediaService: UserMediaService,
    private platform: Platform,
    private webrtcService: WebrtcService,
    private _kortobaaPkgUserService: UserService,
    private _userService: UsersService,
    private _router: Router,
    private _meetingURL: MeetingURLService,
    public _lang: LangService,
    public kortobaaPkg: KortobaaMeetService,
    private meetingsService: MeetingsService,
    private _translate: TranslateService,
    private _meetingEventsListeners: MeetingEventsListenersService,
    private socketService: SocketService,
    private route: ActivatedRoute,
    private rate: RateService
  ) {}

  onNoClick(): void {
    // if (!this.userMediaService.isStreamReady) return;
    this.loading.getData = true;
    setTimeout(() => {
      // for loading state
      this.loading.getData = false;
      this.dialogRef.close();
      this._router.navigate(['/home']);
    }, 500);
  }

  async ngOnInit() {
    // localStorage.removeItem('Token');
    // localStorage.removeItem('outputId');
    // localStorage.removeItem('audioId');
    // localStorage.removeItem('videoId');
    // localStorage.removeItem('audioEnabled');
    // localStorage.removeItem('videoEnabled');

    if (this.route.snapshot.queryParams['lang']) {
      // this._translate.setDefaultLang(this.route.snapshot.queryParams['lang']);
      // this._translate.use(this.route.snapshot.queryParams['lang']);
      this._lang.useLanguage(this.route.snapshot.queryParams['lang']);
    }

    const token = this.route.snapshot.queryParams['userToken'];
    if (token) {
      localStorage.setItem('Token', JSON.stringify(token));
    } else {
      // localStorage.removeItem('Token');
    }
    // localStorage.setItem(
    //   'Token',
    //   JSON.stringify(
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im0uYWJkZWxtb25pZW1Aa29ydG9iYWEuY29tIiwiaWF0IjoxNzEzNjk2ODM2LCJleHAiOjE3MTM5NTYwMzZ9.yB3AlaoBxX7-wk2csShP2KTtF9bpEinjwEpWvfDXiLc'
    //   )
    // );

    const arr = this._router.url.split('/');

    this.rate.callId = arr[arr.length - 1].split('?')[0];

    const meetingToken = this.route.snapshot.queryParams['meetingToken'];
    if (meetingToken) {
      this.socketService.disconnect();
      this.dialogRef.close({
        name: this.route.snapshot.queryParams['name']
          ? this.route.snapshot.queryParams['name']
          : 'agent',
        protocolType: this.protocolType,
        token: meetingToken,
      });
    }

    // const y =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWZyZXNoVG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKbGJXRnBiQ0k2SW5Ob1lXUjVMbTV2YjNKQWEyOXlkRzlpWVdFdVkyOXRJaXdpYVdGMElqb3hOekV6TnpBd09UYzRMQ0psZUhBaU9qRTNNVE01TmpBeE56aDkuTTgwRktsb1BKMkYxanpfSmN2b05WMzIyN0tUdXl2RW9jN0UyS2Mxd3NpOCIsInVzZXJJZCI6MTIyLCJpYXQiOjE3MTM3MDA5NzgsImV4cCI6MTcxNDk5Njk3OH0.NqQVYbjGChR03E6I57y_oPn8pv0-fXacFOqK2ShQvGo';
    // localStorage.setItem('RefreshToken', JSON.stringify(y));

    // const x =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNoYWR5Lm5vb3JAa29ydG9iYWEuY29tIiwiaWF0IjoxNzEzNzAwOTc4LCJleHAiOjE3MTM5NjAxNzh9.M80FKloPJ2F1jz_JcvoNV3227KTuyvEoc7E2Kc1wsi8';

    // localStorage.setItem('Token', JSON.stringify(x));
    // localStorage.setItem('RememberMe', JSON.stringify(true));

    // localStorage.setItem(
    //   'ExpiryDate',
    //   JSON.stringify('2024-04-24T12:02:58.000Z')
    // );

    // localStorage.setItem(
    //   'UserProfile',
    //   JSON.stringify({
    //     id: 122,
    //     username: 'شادي',
    //     email: 'shady.noor@kortobaa.com',
    //     phone: '',
    //     photoUrl: '',
    //     roles: ['users', 'users.update'],
    //     additionalData: {
    //       company: null,
    //       country: 65,
    //       jobTitle: null,
    //       firstName: 'shady',
    //     },
    //   })
    // );

    if (!this.userMediaService.audioStream) {
      this.userMediaService.withAudioAccept = false;
    }

    if (!this.userMediaService.videoStream) {
      this.userMediaService.withVideoAccept = false;
    }

    //clear last meeting info before join
    this.dataService.clearMeetingInfo();
    //check if user open from mobile or tablet

    this.meetingUrl = this._router.url.split('/')[2];
    this.loading.getData = false;
    // this.meetingTitle = this.data.meetingInfo.title;
    // this.getUserMedia();
    // this.meetingsService
    //   .getMeetingDataByUrl('meetings/' + this.meetingUrl)
    //   .subscribe(async (res: any) => {
    //     this.meetingTitle = res.title;
    //     await this.getUserMedia();
    //   });

    if (this.platform.ANDROID || this.platform.IOS) {
      // this.loading.getData = false;
      // this.openAppUi = true;
    } else {
      //if not just get user media & combtabilty
      // await this.getUserMedia();
      // If user choose to turn off camera, skip device change events
      this.userMediaService.deviceChangeHandler(
        !this.userMediaService.withVideoAccept
      );
    }
  }

  //fn to open app in mobile with deep-linking
  openApp() {
    window.open(
      'halameet://meet.halameet.com/meeting/' + this.data.meetingToken
    );
  }

  downloadApp() {
    if (this.platform.ANDROID) {
      window.open(environment.PLAY_STORE_URL);
    } else {
      window.open(environment.APP_STORE_URL);
    }
  }

  //close open in app & open user dialog
  // async closeApp() {
  //   this.openAppUi = false;
  //   await this.getUserMedia();
  // }

  //login action for login form
  async login() {
    //start btn loading animation & disable
    this.loading.login = true;
    this.attempts = 0;
    this.waitingTime = null;
    this.errorNotification = '';
    try {
      //lets send login request
      await this._userService.login({
        login: this.userData.email,
        password: this.userData.password,
        rememberMe: this.userData.rememberMe,
      });

      this.closeGuestLoginForm();

      //stop loading btn
      this.loading.login = false;

      // this.getUserMedia();
    } catch (error) {
      this.loading.login = false;
    }
  }

  copyMeetingUrl() {
    this._meetingURL.copyMeetingUrl(this.data.meetingToken);
  }

  //detect any change in voice input
  changeInput(event) {
    //save new selected device id
    this.data.audioDeviceId = event.value;
  }

  openGuestLoginForm() {
    this.guestLoginForm = true;
    // this.dialog.open(LoginComponent, {
    //   width: '70%',
    //   height: '90%',
    //   direction: this._lang.direction,
    //   autoFocus: false,
    // });
  }

  closeGuestLoginForm() {
    this.guestLoginForm = false;
  }

  openGuestRestorePassForm() {
    this.guestLoginForm = false;
    this.guestRestorePassForm = true;
  }

  closeGuestRestorePassForm() {
    this.guestRestorePassForm = false;
    this.guestLoginForm = true;
  }

  sendRestoreCode() {
    this.guestRestorePassForm = false;
    this.guestNewPassForm = true;
    //send restore code functionality goes here
  }

  closeGuestNewPassForm() {
    this.guestNewPassForm = false;
    this.guestRestorePassForm = true;
  }

  async getUserMedia() {
    //check if user is ios or not chrom or chrome but less than version 81
    /*if (
      this.platform.IOS ||
      !(platform.name.toLowerCase() == 'chrome') ||
      (platform.name.toLowerCase() == 'chrome' &&
        Number(getBrowserVersion()) < this._latestUnsupportedVersion)
    )
      return (this.browserCompatibility = false); //if yes just pop not combtability device*/

    const checkConnection = await this.webrtcService.checkRtcConnection();
    if (checkConnection) {
      this.isUDPConnected = true;
      this.protocolType = checkConnection.type;
    }

    try {
      // if (this.userMediaService.withVideoAccept) {
      await this.userMediaService.startStream(true);
      // }
    } catch (error) {
      this.checkPermission();
    }

    // Check if user is vaild
    this._kortobaaPkgUserService.checkUserValid().then((res) => {
      this._checkUserValidSub = res.subscribe((isUserValid) => {
        // console.log(isUserValid);
        if (isUserValid) {
          const userData = this.dataService.getUserData();
          if (userData) {
            // Get name if user is logged in already
            this.data['name'] = userData.username;
            // prepareName('shady');
          }
        }
        this.loading.getData = false;
      });
    });
  }

  // check permission browser
  async checkPermission() {
    let dialogRef: MatDialogRef<AllowMediaComponent, any>;

    if (
      this.userMediaService.isInputDisabled ||
      this.userMediaService.isVideoDisabled
    ) {
      // Show an allow media dialog for user if his microphone or camera are blocked instead of browser alert
      dialogRef = this.dialog.open(AllowMediaComponent, {
        data: {
          title:
            this.userMediaService.isVideoDisabled &&
            this.userMediaService.isInputDisabled
              ? 'cam-voice-off'
              : this.userMediaService.isVideoDisabled
              ? 'cam-off'
              : this.userMediaService.isInputDisabled
              ? 'mic-off'
              : 'cam-voice-off',
          msg:
            this.userMediaService.isInputDisabled &&
            this.userMediaService.isVideoDisabled
              ? 'open-cam-voice'
              : this.userMediaService.isVideoDisabled
              ? 'open-cam'
              : this.userMediaService.isInputDisabled
              ? 'open-mic'
              : 'open-cam-voice',
        },
        width: '34rem',
        direction: this._lang.direction,
        disableClose: true,
        autoFocus: false,
      });
    }

    if (dialogRef)
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          if (this.userMediaService.isVideoDisabled) {
            navigator.mediaDevices
              .getUserMedia({
                video: true,
              })
              .then(async () => {
                await this.userMediaService.startStream(true);
              })
              .catch(() => {
                // this.checkPermission();
              });
          } else if (this.userMediaService.isInputDisabled) {
            navigator.mediaDevices
              .getUserMedia({
                audio: true,
              })
              .then(async () => {
                await this.userMediaService.startStream(true);
              })
              .catch(() => {
                // this.checkPermission();
              });
          } else {
            navigator.mediaDevices
              .getUserMedia({
                video: true,
                audio: true,
              })
              .then(async () => {
                await this.userMediaService.startStream(true);
              })
              .catch(() => {
                // this.checkPermission();
              });
          }
        });
  }

  //lets check if user enter with video or not
  async JoinSumbit() {
    const data = {
      callId: this.socketService.id,
    };

    this.socketService.emitEvent('connectToQueue', data);

    this.socketService.emitEvent('waitingCall', data);

    this.waitingScreen = true;

    this.socketService.onEvent('getWaitingQueue', (data) => {
      const usersInQueue = data.findIndex((e) => {
        return e.id == this.socketService.id;
      });

      this.currnetQueue = usersInQueue + 1;
      if (this.currnetQueue == 0) {
        this.socketService.onEvent('startMeeting', (data) => {
          const queryParams: Params = { meetingToken: data.token };
          this._router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
          this.socketService.disconnect();
          this.dialogRef.close({
            name: this.route.snapshot.queryParams['name']
              ? this.route.snapshot.queryParams['name']
              : 'agent',
            protocolType: this.protocolType,
            token: data.token,
            // token: 'shady',
          });

          // this._router.navigate(['/meeting/' + data.token]);
        });
      }
    });

    // const interval = setInterval(() => {
    //   if (this.currnetQueue == 0) {
    //     // this.dialogRef.close({
    //     //   name: 'shady',
    //     //   protocolType: this.protocolType,
    //     //   // token: data.token,
    //     //   token: 'VN9IJlLFxlFufOI',
    //     // });
    //     // this._router.navigate(['/meeting/' + data.token]);

    //     this.socketService.onEvent('startMeeting', (data) => {
    //       this.socketService.disconnect();
    //       this.dialogRef.close({
    //         name: this.route.snapshot.queryParams['name']
    //           ? this.route.snapshot.queryParams['name']
    //           : 'agent',
    //         protocolType: this.protocolType,
    //         token: data.token,
    //         // token: 'shady',
    //       });

    //       // this._router.navigate(['/meeting/' + data.token]);
    //     });
    //     clearInterval(interval);
    //   } else {
    //     this.currnetQueue = this.currnetQueue - 1;
    //   }
    // }, 1000);

    this.socketService.onEvent('declineMeeting ', (data) => {});

    // this.dialogRef.close({
    //   name: 'shady',
    //   protocolType: this.protocolType,
    //   token: 'shady',
    // });

    // this.socketService.onEvent('getWaitingQueue', (data) => {
    //   console.log(data);
    //   this.waitingScreen = true;
    // });

    // Emit an event to the server

    // //emit data for meetings component
    // this.dialogRef.close({
    //   name: 'shady',
    //   protocolType: this.protocolType,
    // });
  }

  leaveWaitingRoom() {
    // this.socketService.disconnect();
    // window.close();
    this.dialog.open(CloseDialogComponent, {
      // maxWidth: window.innerWidth > 1024 ? '420px' : '328px',
      // width: window.innerWidth > 1024 ? '420px' : '328px',
      data: {
        title: 'dialog-title-leave',
        desc: 'dialog-desc-leave',
        type: 'leave',
      },
    });
  }

  returnToHomePage() {
    this.dialog.open(CloseDialogComponent, {
      // maxWidth: window.innerWidth > 1024 ? '420px' : '328px',
      // width: window.innerWidth > 1024 ? '420px' : '328px',
      data: {
        title: 'dialog-title-home',
        desc: 'dialog-desc-home',
      },
    });
  }

  // private _allowMedia(imgUrl: string, message: string) {
  //   return this.dialog.open(AllowMediaComponent, {
  //     width: '385px',
  //     direction: this._lang.direction,
  //     data: {
  //       imgUrl,
  //       message,
  //     },
  //   });
  // }

  async onSignUpSubmit() {
    // if any field required scroll to it
    // if (
    //   this.registerForm &&
    //   this.registerForm.form &&
    //   this.registerForm.form.status == 'INVALID'
    // ) {
    //   return this._scrollRequired.scrollToFirstInvalidControl(
    //     'registerForm',
    //     // this.registerForm.form.controls
    //   );
    // }
    // this.signUpResponseData.attempts = 0;
    // this.signUpResponseData.waitingTime = null;
    // this.signUpResponseData.errorFromserver = '';
    // this.signUpResponseData.errorNotification = '';
    // this.loading = true;
    if (this.registerData.password != this.registerData.passConfirmation) {
      // this.loading = false;
      const msg = this._translate.instant('validation.pass-not-match');
      this.dataService.notification(msg, true);
      return;
    }

    let message: string;
    try {
      const filteredAdditionalData = { ...this.registerData.additionalData };

      // Remove empty additional data
      for (let key in filteredAdditionalData) {
        if (
          filteredAdditionalData.hasOwnProperty(key) &&
          filteredAdditionalData[key] === ''
        ) {
          delete filteredAdditionalData[key];
        }
      }

      const signUpdata = {
        email: this.registerData.email,
        password: this.registerData.password,
        // username: `${filteredAdditionalData.firstName} ${filteredAdditionalData.lastName}`,
        username: `${filteredAdditionalData.firstName}`,
        phone: this.registerData.phone,
        additionalData: filteredAdditionalData,
      };

      // TODO: remove the workaround in the signup after change the Register interface in @kortobaa-front/authentication
      await this._userService.signUp(signUpdata as any);
      message = this._translate.instant('login.registered-successfully');
      this.dataService.notification(message);
      // this.loading = false;
      // this.registerForm.resetForm();
      // this.
      this.closeGuestRestorePassForm();
    } catch (error) {
      // this.loading = false;

      if (error.status == 401) {
        message = this._translate.instant('errors.invalid-email-or-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this.dataService.notification(message, true);
    }
  }

  toggle(type: 'audio' | 'video') {
    this.userMediaService.autoSaveEnabledDevices();
    if (type == 'audio') {
      if (!this.userMediaService.withAudioAccept) {
        this.userMediaService.stopStream('audio');
      } else {
        this.userMediaService.changeAudioDevice(true, true);
      }
    }
    if (type == 'video') {
      if (!this.userMediaService.withVideoAccept) {
        this.userMediaService.stopStream('video');
      } else {
        this.userMediaService.changeVideoDevice(true, true);
      }
    }
  }

  // select device and change it
  // async changeDevice(value: string, event: MatSelectChange) {
  //   if (value === 'audio') {
  //     if (event.value != 0) {
  //       this.userMediaService.withAudioAccept = true;
  //       this.toggle('audio');
  //     } else {
  //       this.userMediaService.withAudioAccept = false;
  //       this.toggle('audio');
  //     }
  //   }
  //   if (value === 'video') {
  //     if (event.value != 0) {
  //       this.userMediaService.withVideoAccept = true;
  //       this.toggle('video');
  //     } else {
  //       this.userMediaService.withVideoAccept = false;
  //       this.toggle('video');
  //     }
  //   }

  //   if (value === 'output') {
  //     this.userMediaService.autoSaveMedia();
  //   }
  // }

  async changeDevice(value: string, device) {
    if (value === 'audio') {
      this.userMediaService.audioDeviceId = device;
      localStorage.setItem('audioId', JSON.stringify(device));
      this.userMediaService.autoSaveMedia();
      await this.userMediaService.changeAudioDevice(false, true);
      if (this._lang.videoDisabled) {
        this.resetMeter();
      }
    }
    if (value === 'video') {
      this.userMediaService.videoDeviceId = device;
      localStorage.setItem('videoId', JSON.stringify(device));
      this.userMediaService.autoSaveMedia();
      await this.userMediaService.changeVideoDevice(false, true);
    }
    if (value === 'output') {
      this.userMediaService.autoSaveMedia();
    }
  }

  disableVideo() {
    this.userMediaService.withVideoAccept = false;
    this.userMediaService.stopStream('video');
  }

  disableAudio() {
    this.userMediaService.withAudioAccept = false;
    this.userMediaService.stopStream('audio');
  }

  async AcceptPrivacy() {
    // this.dialog.open(SettingsDialogComponent);

    // return;
    this.privacyAccepted = true;
    this.loading.getData = true;
    if (this._lang.videoDisabled) {
      // this.disableVideo();
      // this.userMediaService.isVideoDisabled = true;
      // this.userMediaService.withVideoAccept = false;
      // this.kortobaaPkg.hasVideo = false;
    }
    await this.getUserMedia();
    if (this._lang.videoDisabled) {
      this.disableVideo();
      setTimeout(() => {
        this.resetMeter();
      }, 500);
    }

    // this.socketService.emitEvent('callAccepted', {
    //   token:
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im0uYWJkZWxtb25pZW1Aa29ydG9iYWEuY29tIiwiaWF0IjoxNzEzNjk2ODM2LCJleHAiOjE3MTM5NTYwMzZ9.yB3AlaoBxX7-wk2csShP2KTtF9bpEinjwEpWvfDXiLc',
    // });
  }

  rejectPrivacy() {
    this.dialog.open(PrivacyRejectComponent, {
      // maxWidth: window.innerWidth > 1024 ? '420px' : '328px',
      // width: window.innerWidth > 1024 ? '420px' : '328px',
    });
  }

  async ngOnDestroy() {
    await this.userMediaService.stopDeviceChangeHandler();
    // close opened streams
    await this.userMediaService.stopStream();
    await this.userMediaService.stopStream('audio');
    await this.userMediaService.stopStream('video');
    this.userMediaService.stopDeviceChangeHandler();
    if (this._checkUserValidSub) this._checkUserValidSub.unsubscribe();
  }
}
