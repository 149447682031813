import { Injectable } from '@angular/core';

import {
  MeetingSettings,
  MeetingsHelperService,
  MeetingTemplate,
  RoomInfo,
} from '@kortobaa-front/ng-kmeet-sdk';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable()
export class MeetingsService {
  constructor(
    private _dataService: DataService,
    private _meetingsHelperService: MeetingsHelperService
  ) {}

  checkMeetingForNonUser(roomToken: string) {
    return this._meetingsHelperService.checkMeetingForNonUser(roomToken);
  }

  getPersonalRoom() {
    const { username } = this._dataService.getUserData();
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.getPersonalRoom(username, token);
  }

  savePersonalRoom(roomInfo: RoomInfo) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.savePersonalRoom(roomInfo, token);
  }

  generateMeeting(meetingSettings: MeetingSettings) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.generateMeeting(meetingSettings, token);
  }

  getUpcommingMeetingsCount() {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.getUpcommingMeetingsCount(token);
  }

  getUpcommingMeetings(limit: number, offset: number) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.getUpcommingMeetings(
      token,
      limit,
      offset
    );
  }

  cancelUpcommingMeeting(id: number) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.cancelUpcommingMeeting(id, token);
  }

  updateScheduleMeeting(id: number, newData) {
    const token = this._dataService.getUserToken();

    return this._meetingsHelperService.updateScheduleMeeting(
      id,
      newData,
      token
    );
  }

  getMeetingDataByUrl(url: string) {
    return this._meetingsHelperService.getMeetingDataByUrl(url);
  }

  getAllMeetingTemplate() {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.getAllMeetingTemplate(token);
  }

  saveMeetingTemplate(meetingTemplate: MeetingTemplate) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.saveMeetingTemplate(
      meetingTemplate,
      token
    );
  }

  updateMeetingTemplate(id: number, updatedMeetingTemplate: MeetingTemplate) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.updateMeetingTemplate(
      id,
      updatedMeetingTemplate,
      token
    );
  }

  validatePersonalToken(id: string) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.validatePersonalToken(id, token);
  }

  changePersonalToken(id: string) {
    const token = this._dataService.getUserToken();
    return this._meetingsHelperService.changePersonalToken(id, token);
  }

  // New

  listenToWaiting(id) {
    return;
  }
}
