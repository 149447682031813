import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SocketService } from '../services/socket.service';

@Injectable({
  providedIn: 'root',
})
export class RateService {
  constructor(private http: HttpClient, private socket: SocketService) {}

  callId: any;

  rateCall(id, data) {
    return this.http.post(
      environment.url + 'api/customer/calls/rate/' + this.socket.id,
      data
    );
  }
}
