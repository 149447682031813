import {
  Component,
  OnInit,
  Input,
  HostListener,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationInstance } from 'ngx-pagination';
import { LangService } from 'src/app/shared/services/lang.service';
import { DataService } from 'src/app/shared/services/data.service';
import { CustomSnackbarComponent } from 'src/app/meeting/custom-snackbar/custom-snackbar.component';

// NOTE: uncomment next import to test meeting with mocked data.
import {
  meetingMembers,
  addMemberToMeeting,
  removeMemberFromMeeting,
} from 'src/app/meeting/mocks/meetingMembers.mock';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { MeetingsService } from '../services/meetings.service';
import { formatDate, formatTime } from 'src/app/shared/helpers/convertDate';
import { CloseDialogComponent } from '../close-dialog/close-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-meeting-screen',
  templateUrl: './meeting-screen.component.html',
  styleUrls: ['./meeting-screen.component.scss'],
})
export class MeetingScreenComponent implements OnInit, OnDestroy {
  @Input() meetingMembers = [];
  @Input() meetingParticipants = [];
  @Input() activeSpeakers = [];
  @Input() sharingScreen;
  @Input() viewingScreen;
  @Input() roomTitle;
  @Input() viewOptions: any;
  @Input() host: boolean = false;
  @Input() waitingRoom = [];
  @Input() waitingScreen = false;
  @Input() waitingScreenData;
  @Input() waitingRoomDelivered;
  @Input() sideCollapsed;
  @Input() meetingInfo;
  @Output() sharedScreenStopped = new EventEmitter<null>();

  public formatDate = formatDate;
  public formatTime = formatTime;

  // NOTE: uncomment next line and replace every meetingMembers in the template with meetingMembersMock to test meeting with mocked data.
  // meetingMembersMock = meetingMembers;

  // NOTE: uncomment next functionality and corresponding buttons in template to add or remove meeting members of mocked data.
  // addMemberToMeeting = addMemberToMeeting;
  // removeMemberFromMeeting = removeMemberFromMeeting;

  config: PaginationInstance;

  screensLength: number = 0;

  topBar = {
    activeSpeaker: true,
    viewingScreen: false,
    recording: false,
  };

  showActiveSpeakerAndMeetingTitle = true;

  constructor(
    public dataService: DataService,
    public lang: LangService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public kortobaaPkg: KortobaaMeetService,
    private _meetingService: MeetingsService,
    private dialog: MatDialog
  ) {
    this.config = {
      itemsPerPage: this.dataService.meetingScreensGrid,
      currentPage: 1,
      totalItems: this.meetingMembers.length,
    };
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  ngOnInit(): void {
    /* check if we have share screen with video at the same time */
    if (this.viewingScreen.video) {
      this.dataService.sharingScreenWithVideo = true;
    }
  }

  fixCurrentPage(e) {
    this.config.currentPage = e;
  }

  pauseRecording() {
    this.dataService.pause();
  }

  resumeRecording() {
    this.dataService.resume();
  }

  stopRecording() {
    //reset recording timer
    this.dataService.stop();
    let message: string;
    if (this.lang.currentLang == 'ar') {
      message = 'سيتم تنزيل تسجيل الاجتماع عند انتهاء الاجتماع.';
    } else {
      message = 'Your record will be downloaded when meeing ends.';
    }

    // this._snackBar.openFromComponent(CustomSnackbarComponent, {
    //   duration: 2000,
    //   data: message,
    //   direction: this.lang.direction,
    //   panelClass: ['accent-snackbar', 'control-bar-confirmation'],
    // });
  }

  stopSharingScreen() {
    this.sharedScreenStopped.emit();
  }

  fullScreen(id) {
    let container = document.getElementById(id);

    if (container.requestFullscreen) {
      container.requestFullscreen();
    }

    this.viewingScreen.fullScreen = true;
  }

  exitFullSCreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    this.viewingScreen.fullScreen = false;
  }

  //exit full scrren on escape button pressed
  @HostListener('document:fullscreenchange', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (!document.fullscreenElement) {
      this.viewingScreen.fullScreen = false;
    }
  }

  navigateToMeetingVideos() {
    this.viewingScreen.activePage = false;
  }

  navigateToSharedScreen() {
    this.viewingScreen.activePage = true;
  }

  collapseFloatingVideo() {
    this.viewingScreen.collpaseVideo = true;
  }

  unCollapseFloatingVideo() {
    this.viewingScreen.collpaseVideo = false;
  }

  goToHome() {
    // this._router.navigate(['/home']);
    window.close();
  }

  muteUser(media) {
    if (this.kortobaaPkg.isHost) {
      if (this.kortobaaPkg.userId != media.userId) {
        this.dialog
          .open(CloseDialogComponent, {
            data: {
              title: 'mute-title',
              desc: 'mute-desc',
              type: 'mute',
            },
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              this.kortobaaPkg.muteUser({
                action: 'mute',
                member: { id: media.userId },
              });

              // let memberIndex = this.meetingMembers.indexOf(media);
              // this.meetingMembers[memberIndex].audio = false;
            }
          });
      } else {
        this.kortobaaPkg.muteUser({
          action: 'mute',
          member: { id: media.userId },
        });
      }
    }
  }

  unMuteUser(media) {
    if (this.kortobaaPkg.isHost) {
      this.kortobaaPkg.muteUser({
        action: 'unmute',
        member: { id: media.userId },
      });

      // let memberIndex = this.meetingMembers.indexOf(media);
      // this.meetingMembers[memberIndex].audio = true;
    }
  }

  // future feature

  // pinParticipant(index) {
  //   // this.meetingMembers.reverse();
  //   this.meetingMembers[index].pinned = true;
  //   this.meetingMembers.sort((a, b) => {
  //     return a?.pinned! === b?.pinned! ? 0 : a?.pinned! ? -1 : 1;
  //   });
  // }

  // unpinParticipant(index) {
  //   this.meetingMembers[index].pinned = false;
  //   this.meetingMembers.sort((a, b) => {
  //     return a?.pinned! === b?.pinned! ? 0 : a?.pinned! ? -1 : 1;
  //   });

  //   this.meetingMembers.sort((a, b) => {
  //     return a.userId - b.userId;
  //   });

  //   console.log(this.meetingMembers);
  // }

  ngOnDestroy(): void {
    // localStorage.removeItem('Token');
    // localStorage.removeItem('outputId');
    // localStorage.removeItem('audioId');
    // localStorage.removeItem('videoId');
  }
}
