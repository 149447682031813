'use strict';

const crypto = require('crypto');

function encrypt(text,ENCRYPTION_KEY,IV_LENGTH,ENC_TYPE,ENC_MODE) {
  let iv = new Buffer.alloc(IV_LENGTH);
  let cipher = crypto.createCipheriv(ENC_MODE, ENCRYPTION_KEY, iv);
  let encryptedData = cipher.update(text, 'utf8', ENC_TYPE) + cipher.final(ENC_TYPE);
  return encryptedData;
}

function decrypt(text,ENCRYPTION_KEY,IV_LENGTH,ENC_TYPE,ENC_MODE) {
  let iv = Buffer.alloc(IV_LENGTH, 0);
  let encryptedText = Buffer.from(text, ENC_TYPE);
  let decipher = crypto.createDecipheriv(ENC_MODE, Buffer.from(ENCRYPTION_KEY), iv);
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
}

module.exports = { decrypt, encrypt };
